import { ValidationError } from "jacy";
import type { IJacyEventsEmitter, IJacyContent, IAssetKey } from "jacy";
import { JacyClient } from "@jacy-client";

import { JacyThumbnailActions } from "./JacyActions/JacyThumbnailActions";
import { JacyCustomLoaderActions } from "./JacyActions/JacyCustomLoaderActions";
import { JacyGameMechanicsActions } from "./JacyActions/JacyGameMechanicsActions";
import { JacyPermissionsActions } from "./JacyActions/JacyPermissionsActions";
import { JacySettingsActions } from "./JacyActions/JacySettingsActions";
import { JacySkyboxActions } from "./JacyActions/JacySkyboxActions";
import { JacyEnvironmentPresetActions } from "./JacyActions/JacyEnvironmentPresetActions";
import { JacyBackgroundMusicActions } from "./JacyActions/JacyBackgroundMusicActions";
import { JacyBlockTextureActions } from "./JacyActions/JacyBlockTextureActions";
import { JacyBlockActions } from "./JacyActions/JacyBlockActions";
import { JacyCharacterActions } from "./JacyActions/JacyCharacterActions";
import { JacyAvatarActions } from "./JacyActions/JacyAvatarActions";
import { JacyAudioActions } from "./JacyActions/JacyAudioActions";
import { JacyWorldTagsActions } from "./JacyActions/JacyWorldTagsActions";
import { useGameClientStore } from "@stores/game-client/game-client";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { JacyWorldDataActions } from "./JacyActions/JacyWorldDataActions";
import { useGeneralNotificationStore } from "@stores/dialogs/general-notification";
import { JacyChangesetActions } from "./JacyActions/JacyChangesetActions";
import { JacyCostumeActions } from "./JacyActions/JacyCostumeActions";
import { JacyWorldActions } from "./JacyActions/JacyWorldActions";

export class JacyActions {
	client: JacyClient;
	content: IJacyContent;
	state: IJacyContent["state"];
	events: IJacyEventsEmitter;

	thumbnail: JacyThumbnailActions;
	customLoader: JacyCustomLoaderActions;
	gameMechanics: JacyGameMechanicsActions;
	permissions: JacyPermissionsActions;
	settings: JacySettingsActions;
	skybox: JacySkyboxActions;
	environmentPresets: JacyEnvironmentPresetActions;
	backgroundMusic: JacyBackgroundMusicActions;
	blockTexture: JacyBlockTextureActions;
	block: JacyBlockActions;
	character: JacyCharacterActions;
	avatar: JacyAvatarActions;
	audio: JacyAudioActions;
	worldTags: JacyWorldTagsActions;
	worldData: JacyWorldDataActions;
	changesets: JacyChangesetActions;
	costumes: JacyCostumeActions;
	world: JacyWorldActions;

	constructor(client: JacyClient) {
		this.client = client;
		this.content = client.content;
		this.events = client.events;
		this.state = client.state;

		this.worldData = new JacyWorldDataActions(this);
		this.thumbnail = new JacyThumbnailActions(this);
		this.customLoader = new JacyCustomLoaderActions(this);
		this.gameMechanics = new JacyGameMechanicsActions(this);
		this.permissions = new JacyPermissionsActions(this);
		this.settings = new JacySettingsActions(this);
		this.skybox = new JacySkyboxActions(this);
		this.environmentPresets = new JacyEnvironmentPresetActions(this);
		this.backgroundMusic = new JacyBackgroundMusicActions(this);
		this.blockTexture = new JacyBlockTextureActions(this);
		this.block = new JacyBlockActions(this);
		this.character = new JacyCharacterActions(this);
		this.avatar = new JacyAvatarActions(this);
		this.audio = new JacyAudioActions(this);
		this.worldTags = new JacyWorldTagsActions(this);
		this.changesets = new JacyChangesetActions(this);
		this.costumes = new JacyCostumeActions(this);
		this.world = new JacyWorldActions(this);
	}

	async init() {
		this.backgroundMusic.init();
		await this.changesets.init();
	}

	shouldCloneWorld() {
		if (!this.content.options.isImported) return false;
		if (!(this.state.user.isCreator && this.state.isServer)) return false;

		const world = this.state.world.get();
		if (!world.isNew) return false;

		return true;
	}

	reset() {
		this.backgroundMusic.reset();
	}

	getChangeset<Changeset>(id: IAssetKey) {
		return this.content.getChangeset<Changeset>(id);
	}

	updateChangeset<Changeset>(
		id: IAssetKey,
		type: "create" | "update" | "delete" | "world:update",
		data: Partial<Changeset> = {},
	) {
		this.events.emit("content:update", { id, type, data });

		this.content.updateChangeset<Changeset>(id, type, (asset) => {
			const currentAsset = asset as any;

			for (const key in data) {
				if (
					data[key] instanceof File ||
					data[key] instanceof Blob ||
					data[key] instanceof ArrayBuffer
				) {
					currentAsset[key] = data[key];
				} else if (data[key] === null) {
					currentAsset[key] = data[key];
				} else if (data[key] === undefined) {
					currentAsset[key] = data[key];
				} else if (Array.isArray(currentAsset[key])) {
					currentAsset[key] = data[key];
				} else if (typeof currentAsset[key] === "object") {
					currentAsset[key] = {
						...currentAsset[key],
						...data[key],
					};
				} else {
					currentAsset[key] = data[key];
				}
			}

			return currentAsset;
		});

		useJacyRerenderStore.getState().forceRerenderChangeset();
	}

	setInfo(message: string) {
		useGameClientStore.getState().setNotification({ type: "info", message });
	}

	setSuccess(message: string) {
		useGameClientStore.getState().setNotification({ type: "success", message });
	}

	setError(error: unknown) {
		if (error instanceof Error) {
			useGameClientStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: error.message,
			});
		} else {
			console.error(error);
		}
	}

	setGeneralInfo(message: string) {
		useGeneralNotificationStore
			.getState()
			.setNotification({ type: "info", message });
	}

	setGeneralSuccess(message: string) {
		useGeneralNotificationStore
			.getState()
			.setNotification({ type: "success", message });
	}

	setGeneralError(error: unknown) {
		if (error instanceof Error) {
			useGeneralNotificationStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: error.message,
			});
		} else {
			console.error(error);
		}
	}

	setValidationsErrors(error: unknown) {
		if (error instanceof Error) {
			useGameClientStore.getState().setNotification({
				type: "error",
				message:
					error instanceof ValidationError
						? error.issues.map((issue) => issue.message)
						: error.message,
			});
		} else {
			console.error(error);
		}
	}
}
