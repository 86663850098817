import { lib, BundleType, JSZip, JacyCore } from "jacy";
import { helpers, constants } from "rest-client";

import { JacyClient } from "@jacy-client";
import { IWorldSaveEvent, IWorldSavingEvent } from "../websockets/JacyWebsockets";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";

const FEATURE_FLAGS = constants.experiments.FEATURE_FLAGS;

export class JacySyncer {
	static VERSION = 1;
	SAVE_ERROR = {
		UNAUTHORIZED: "unauthorized",
	};

	jacy: JacyClient;

	constructor(jacy: JacyClient) {
		this.jacy = jacy;
	}

	async saveWorld() {
		this.jacy.content.state.world.assert();

		const { id, identifier, changeset, version } = await this.#stageChangesets();

		const zip = new JSZip();
		zip.file("changeset.json", JSON.stringify(changeset.data), { base64: false });

		const files = zip.folder("files");

		if (!files) {
			throw new Error("Failed to create files folder in zip");
		}

		for (const key in changeset.files) {
			const changesetFiles = changeset.files[key];

			for (const [filename, data] of changesetFiles) {
				files.file(`${key}/${filename}`, data, { base64: true });
			}
		}

		const content = await zip.generateAsync({ type: "blob" });

		const useDelayedJobs = this.jacy.state.user.featureFlags.includes(
			FEATURE_FLAGS.DELAYED_JOBS,
		);
		let requestId = JacyCore.generateUUID();

		const formData = new FormData();
		formData.append("file", content, `jamango-world-${id}-${version}-changeset.zip`);

		if (useDelayedJobs) {
			// eslint-disable-next-line no-console
			console.log("Using delayed jobs for saving...");
			const response = await this.jacy.api.saveWorldV2(
				identifier ?? "new",
				version,
				formData,
			);
			requestId = response.requestId;
		} else {
			formData.append("requestId", requestId);

			// TODO: Replace this temporary hack to make save world job work.
			// Currently, instead of running the save world job logic in a background job, we run it in the API request instead.
			// This is prone to server timeout if the request it too big to process.
			this.jacy.api.saveWorld(identifier ?? "new", version, formData);

			const uploadingStatus = await this.jacy.websockets.createRequestPromise<
				true | { error?: string[]; auth?: boolean }
			>(`world:uploading:${requestId}`);

			if (typeof uploadingStatus === "object") {
				if (uploadingStatus.error) {
					throw new Error(uploadingStatus.error.join(". "));
				} else {
					throw new Error(this.SAVE_ERROR.UNAUTHORIZED);
				}
			}
		}

		const unsubscribe = this.jacy.websockets.on(
			`world:saving:${requestId}`,
			(response: IWorldSavingEvent) => {
				this.jacy.events.emit("syncer:progress", response);
			},
		);

		const response =
			await this.jacy.websockets.createChunkRequestPromise<IWorldSaveEvent>(
				`world:save:${requestId}`,
			);

		unsubscribe();

		this.#commitChangesets(response);

		return response.identifier;
	}

	#commitChangesets({ identifier, success, errors }: IWorldSaveEvent) {
		// TODO: Investigate why `errors` at rare times, becomes undefined here.
		if (errors?.length) {
			console.error("Failed to save the following changeset:", errors);

			const errorMessage = errors
				.map(
					(error) =>
						`${lib.helpers.strings.camelCasetoTitleCase(error.resource)}: ${error.error}.`,
				)
				.join("\n");

			useAlertDialogStore.getState().setAlert({
				title: "Failed to save the world",
				message: `There was an error saving the world.\n${errorMessage}`,
			});

			this.jacy.content.state.changesets.cancelStagedChangesets();
			return;
		}

		const pks = success
			.map((success) => success.pk)
			.filter(helpers.arrays.onlyUnique);

		this.jacy.content.state.onSave(identifier, pks);
		this.jacy.content.state.changesets.commitChangesets();
	}

	async #stageChangesets() {
		if (!this.jacy.state.user.canSave) {
			throw new Error("User is not allowed to save the world.");
		}

		const world = this.jacy.content.export();

		if (!world) {
			throw new Error("World has not been loaded yet.");
		}

		const changesets = this.jacy.content.state.changesets.stageChangesets();
		const engine = await this.jacy.getEngine();
		const mapFile = engine.getMapFile();

		const bundleKey = `${world.data.id}--${world.data.version.replaceAll(".", "-")}`;

		const today = new Date().toISOString();

		const mapChangeset = {
			pk: `map#${bundleKey}`,
			type: world.data.identifier ? ("update" as const) : ("create" as const),
			terrainGenerationOptions: world.data.assets.map.terrainGenerationOptions,
			file: mapFile,
			size: 0,
			createdAt: today,
			updatedAt: today,
		};
		mapChangeset.size = lib.helpers.files.getDataByteSize(mapChangeset);

		changesets.push(mapChangeset);

		const sortedChangesets = lib.helpers.bundle.sortBundleAssets(
			BundleType.WORLD,
			changesets,
		);
		const formattedChangesets: Record<string, any>[] = [];
		const files: { [assetKey: string]: Map<string, File> } = {};

		sortedChangesets.forEach((changeset) => {
			const formattedChangeset = lib.helpers.files.extractFilesInData(changeset);
			formattedChangesets.push(formattedChangeset.data);

			if (formattedChangeset.files.size > 0) {
				files[changeset.pk] = formattedChangeset.files;
			}
		});

		return {
			id: world.data.id,
			identifier: world.data.identifier,
			version: world.data.version,
			changeset: {
				data: {
					id: world.data.id,
					version: JacySyncer.VERSION,
					changesets: formattedChangesets,
				},
				files,
			},
		};
	}
}
