import { create } from "zustand";
import { constants } from "rest-client";
import type { IPlayerPermissions } from "jacy";
import { lib, JacyCore } from "jacy";
import { Jacy } from "@jacy-client";

import { useSelectorStore } from "./hud/selector";
import { Engine } from "./bb";
import { useInventoryStore } from "./dialogs/inventory";
import { useItemStore } from "./hud/item";
import { useJacyUserStore } from "./jacy/user";
import { useMultiplayerStore } from "./game-client/multiplayer";
import { useTestWorldStore } from "./test-world";

export const MODE = {
	default: "default",
	building: "building",
	spawner: "spawner",
	driving: "driving",
	fighting: "fighting",
	pencil: "pencil",
	wrench: "wrench",

	creator: "creator",
	cameraTool: "cameraTool",
	controllingCameraDrone: "controllingCameraDrone",
};

interface IPlayerState {
	username: string;
	mode: string;
	health: number;
	maxHealth: number;

	setMode: (mode: string) => void;
	setUsername: (username?: string | null) => void;
	setHealth: (health: number) => void;
	setMaxHealth: (health: number) => void;
}

export const usePlayerStore = create<IPlayerState>((set) => ({
	username: getGuestUsername(),
	mode: MODE.default,
	health: 100,
	maxHealth: 100,

	// Handlers
	setMode: (mode) => {
		if (!(mode in MODE)) throw new Error(`Invalid mode: ${mode}`);

		set({ mode });
	},
	setUsername: (username) => set({ username: username ?? getGuestUsername() }),
	setHealth: (health) => set({ health }),
	setMaxHealth: (health) => set({ maxHealth: health }),
}));

function getGuestUsername() {
	return `Guest ${JacyCore.generateId().substring(0, 6)}`;
}

export enum Mode {
	DEFAULT,
	CREATOR,
	PLAYER,
}

interface IPermissionState {
	mode: Mode;
	permissions: IPlayerPermissions;

	setMode: (mode: Mode) => void;
	isCreatorSlots: () => boolean;
	checkCanBuild: (canBuild?: boolean) => boolean;
	resetCreatorsCanBuild: (canBuild: boolean) => void;
	setPermissions: (permissions: Partial<IPlayerPermissions>) => void;
	setPlayerMode: (playerMode: boolean) => void;
}

let lastMode: Mode | null = null;

// Current player permission during gameplay.
export const usePermissionStore = create<IPermissionState>((set, get) => ({
	mode: Mode.DEFAULT,
	permissions: lib.constants.permissions.DEFAULT_PERMISSIONS.Creator,

	// Handlers
	setMode: (mode) => set({ mode }),
	isCreatorSlots: () => {
		const isServer = useMultiplayerStore.getState().isServer;
		const isPublicServer = useMultiplayerStore.getState().isPublicServer;
		const isOwner = useJacyUserStore.getState().isOwner;
		const isCollaborator = useJacyUserStore.getState().isCollaborator;

		if (isPublicServer) return false;
		if (!isServer) return false;
		if (!(isOwner || isCollaborator)) return false;

		const mode = Jacy.state.worldData.get().mode;
		const testType = useTestWorldStore.getState().type;

		if (mode === constants.world.WORLD_MODE.CREATOR) {
			const canBuild = get().permissions.canBuild;
			const showTestSlots =
				testType !== constants.world.TEST_WORLD_TYPE.none || !canBuild;

			const { equippedItem } = useItemStore.getState();
			const isHoldingWrench =
				equippedItem === constants.selector.TOOL.ItemWrench.defName;

			return isHoldingWrench || showTestSlots;
		}

		return false;
	},
	checkCanBuild: (canBuild) => {
		const playerCanBuild = !lib.helpers.general.isNullish(canBuild)
			? canBuild
			: get().permissions.canBuild;

		const { setSelectedSlot, setSelectedBlock, selectedSlot } =
			useSelectorStore.getState();

		if (playerCanBuild === false) {
			setSelectedBlock(null);
			return false;
		}

		const isCreatorSlots = get().isCreatorSlots();

		if (!isCreatorSlots) setSelectedSlot(selectedSlot);

		return true;
	},
	setPermissions: (permissions = {}) => {
		set((state) => ({
			permissions: {
				...state.permissions,
				...permissions,
			},
		}));

		get().checkCanBuild(permissions.canBuild);
	},

	// Actions
	resetCreatorsCanBuild: () => {
		const { BB } = new Engine();
		if (!BB.world) return;

		BB.world.entities.forEach((character: any) => {
			const isPlayer = character.type.def.isPlayer;

			if (!isPlayer) return;
			if (character.def !== BB.world.creatorDef) return;

			character.setCanBuild(true);
		});
	},
	setPlayerMode: (playerMode) => {
		if (playerMode) {
			lastMode = get().mode;
			get().setMode(Mode.PLAYER);

			const { BB, client } = new Engine();
			if (!BB.world) return;

			const player = BB.world[client].camera?.target;
			if (!player) return;

			const permissions = Jacy.state.permissions.Player;
			player.setPermissions(permissions);

			if (!permissions.canBuild) {
				useInventoryStore.setState({ open: false });
			}
		} else {
			get().setMode(lastMode ?? Mode.DEFAULT);
			lastMode = null;

			const { BB, client } = new Engine();
			if (!BB.world) return;

			const player = BB.world[client].camera?.target;
			if (!player) return;

			const permissions = Jacy.state.permissions.Creator;
			player.setPermissions(permissions);

			if (!permissions.canBuild) {
				useItemStore.getState().cycleTool();
			}
		}
	},
}));

export default {
	usePermission: usePermissionStore.getState,
	usePlayer: usePlayerStore.getState,
};
