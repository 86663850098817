/* eslint-disable etc/no-commented-out-code */
import {
	ToneMapping,
	type BlockTypeName,
	type DeepPartial,
	type IEnvironmentPreset,
	type TerrainGenerationOptions,
} from "jacy";
import { constants } from "rest-client";
import {
	CANOPY_TREE,
	createPlatform,
	FERN_PLANT,
	SHORT_PALM_TREE,
	SNOWY_TUNDRA_CRACK_IN_ICE,
	SNOWY_TUNDRA_ICE,
	SNOWY_TUNDRA_ICE_SPIKE1,
	SNOWY_TUNDRA_ICE_SPIKE2,
	SNOWY_TUNDRA_ICE_SPIKE3,
	SNOWY_TUNDRA_PINE_TREE,
	TALL_PALM_TREE,
	WILD_WEST_DESERT_CACTUS1,
	WILD_WEST_DESERT_CACTUS2,
	WILD_WEST_DESERT_CACTUS3,
	WILD_WEST_DESERT_ROCK1,
	WILD_WEST_DESERT_ROCK2,
} from "./objects";

type InventorySlot =
	| { type: "block"; id: BlockTypeName }
	| { type: "character"; id: string };

export type WorldTemplateOptions = {
	terrainGeneration: TerrainGenerationOptions;
	defaultInventory?: InventorySlot[];
	envPresetSettings?: DeepPartial<IEnvironmentPreset["preset"]>;
};

const BARREN_DESERT_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: [],
	ground: {
		amplitude: 2,
		vrTransition: 15,
		noiseOptions: { scale: 500, octaves: 3 },
		foundationLevel: -10,
		topBlock: "bb.block.world.sand",
		midBlock: "bb.block.world.sand",
		foundationBlock: "bb.block.world.sand",
	},
};

const TROPICAL_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksTropical"],
	ground: {
		amplitude: 13,
		vrTransition: 5,
		noiseOptions: { scale: 400, octaves: 5 },
		foundationLevel: -3,
		topBlock: "bb.block.tropical.jungle-moss",
		midBlock: "bb.block.tropical.jungle-mud",
		foundationBlock: "bb.block.tropical.jungle-mud",
	},
	water: {
		level: 4,
		sandBlock: "bb.block.world.sand",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 5,
			maxHeight: 13,
			forestProbability: 0.5,
			probabilityForest: 0.009,
			probabilityNonForest: 0.009,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: CANOPY_TREE,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: TALL_PALM_TREE,
					},
					probability: 0.25,
				},
				{
					factory: {
						type: "static",
						template: SHORT_PALM_TREE,
					},
					probability: 0.5,
				},
				{
					factory: {
						type: "static",
						template: FERN_PLANT,
					},
					probability: 2,
				},
			],
		},
	],
};

const SNOWY_TUNDRA_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksTropical"],
	ground: {
		amplitude: 7,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -10,
		topBlock: "bb.block.tropical.snowy-grass",
		midBlock: "bb.block.world.dirt",
		foundationBlock: "bb.block.world.stone",
	},
	mountains: {
		amplitude: 200,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.35,
		mountainBlock: "bb.block.world.snow",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 2,
			maxHeight: 7,
			forestProbability: 0.5,
			probabilityForest: 0.009,
			probabilityNonForest: 0.009,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_PINE_TREE,
					},
					probability: 1,
				},
			],
		},
		{
			type: "perlinNoise",
			minHeight: -1,
			maxHeight: 0,
			forestProbability: 0.5,
			probabilityForest: 0.999,
			probabilityNonForest: 0.999,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_CRACK_IN_ICE,
					},
					probability: 0.01,
				},
			],
		},
		{
			type: "perlinNoise",
			minHeight: -1,
			maxHeight: 0,
			forestProbability: 1,
			probabilityForest: 0.005,
			probabilityNonForest: 0,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE1,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE2,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: SNOWY_TUNDRA_ICE_SPIKE3,
					},
					probability: 1,
				},
			],
		},
	],
};

const DEEP_SPACE_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksSpaceStation"],
	objectsPlacers: [
		{
			type: "static",
			objects: [
				{
					factory: {
						type: "static",
						template: createPlatform(
							0,
							0,
							0,
							10,
							1,
							10,
							"bb.block.spaceStation.metal-grate-1",
						),
					},
					positions: [[-5, -1, -5]],
				},
			],
		},
	],
};

const FANTASY_GRASSLANDS_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksKingdomAndWizardry"],
	ground: {
		amplitude: 13,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -10,
		topBlock: "bb.block.kingdomAndWizardry.dryGrassOne",
		midBlock: "bb.block.kingdomAndWizardry.dry-dirt-1",
		foundationBlock: "bb.block.kingdomAndWizardry.limestone-1",
	},
	mountains: {
		amplitude: 200,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.35,
		mountainBlock: "bb.block.kingdomAndWizardry.limestone-1",
	},
	snow: {
		min: 50,
		max: 80,
		snowBlock: "bb.block.kingdomAndWizardry.snow-1",
		noiseOptions: { scale: 50, octaves: 3 },
	},
	water: {
		level: 4,
		sandBlock: "bb.block.kingdomAndWizardry.beach-sand-1",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 5,
			maxHeight: 13,
			forestProbability: 0.42,
			probabilityForest: 0.006,
			probabilityNonForest: 0.0004,
			perlinOptions: {
				scale: 150,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: [
							[0, 0, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 1, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 2, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 3, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 4, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 5, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 6, 0, 15, "bb.block.kingdomAndWizardry.silver-birch-bark-1"],
							[0, 7, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 8, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 3, 0, 83, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 3, 0, 92, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 4, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 4, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 5, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 5, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 6, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 6, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 7, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 7, 0, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 8, 0, 44, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 8, 0, 35, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 3, -1, 90, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 3, 1, 85, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 4, -1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 4, 1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 5, -1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 5, 1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 6, -1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 6, 1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 7, -1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 7, 1, 15, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 8, -1, 42, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 8, 1, 37, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 4, 1, 164, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 5, 1, 59, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 6, 1, 30, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 7, 1, 59, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 4, 1, 161, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 5, 1, 27, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 6, 1, 78, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 7, 1, 78, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 4, -1, 162, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 5, -1, 75, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 6, -1, 75, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[1, 7, -1, 75, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 4, -2, 85, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 4, 2, 90, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 5, -2, 101, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 5, 2, 26, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 6, -2, 101, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[0, 6, 2, 26, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 4, -1, 168, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 5, -1, 62, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 6, -1, 109, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-1, 7, -1, 109, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-2, 4, 0, 92, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[2, 4, 0, 83, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-2, 5, 0, 58, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[2, 5, 0, 74, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[-2, 6, 0, 58, "bb.block.kingdomAndWizardry.birch-leaves-3"],
							[2, 6, 0, 74, "bb.block.kingdomAndWizardry.birch-leaves-3"],
						],
					},
					probability: 1,
				},
			],
		},
	],
};

const WILD_WEST_DESERT_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksWildWest"],
	ground: {
		amplitude: 5,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -3,
		topBlock: "bb.block.wildwest.sand-rough",
		midBlock: "bb.block.wildwest.gravel-2",
		foundationBlock: "bb.block.wildwest.sandstone",
	},
	mountains: {
		amplitude: 100,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.3,
		mountainBlock: "bb.block.wildwest.sandstone",
	},
	water: {
		level: 1,
		sandBlock: "bb.block.wildwest.sand-rough",
	},
	objectsPlacers: [
		{
			type: "perlinNoise",
			minHeight: 2,
			maxHeight: 5,
			forestProbability: 0.5,
			probabilityForest: 0.005,
			probabilityNonForest: 0.005,
			perlinOptions: {
				scale: 300,
				octaves: 1,
			},
			objects: [
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS1,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS2,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_CACTUS3,
					},
					probability: 1,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_ROCK1,
					},
					probability: 0.25,
				},
				{
					factory: {
						type: "static",
						template: WILD_WEST_DESERT_ROCK2,
					},
					probability: 0.25,
				},
			],
		},
	],
};

type WorldTemplate = {
	title: string;
	description: string;
	thumbnailSrc: string;
	options: WorldTemplateOptions;
};

export const BlankWorldTemplate: WorldTemplate = {
	title: `Blank`,
	description: "An empty flat world",
	thumbnailSrc: constants.images.IMAGES.EMPTY_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "blank" },
		defaultInventory: [
			{ type: "block", id: "bb.block.color.red.tile" },
			{ type: "block", id: "bb.block.color.red.halftile" },
			{ type: "block", id: "bb.block.color.red.quartertile" },
			{ type: "block", id: "bb.block.color.orange.tile" },
			{ type: "block", id: "bb.block.color.orange.halftile" },
			{ type: "block", id: "bb.block.color.orange.quartertile" },
			{ type: "block", id: "bb.block.color.yellow.tile" },
			{ type: "block", id: "bb.block.color.yellow.halftile" },
			{ type: "block", id: "bb.block.color.yellow.quartertile" },
		],
	},
};

const MVRWorldTemplate: WorldTemplate = {
	title: `Grass and Mountains`,
	description: "A world with rivers, grass and mountains",
	thumbnailSrc: constants.images.IMAGES.DEFAULT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "mvr" },
		defaultInventory: [
			{ type: "block", id: "bb.block.world.grass" },
			{ type: "block", id: "bb.block.world.dirt" },
			{ type: "block", id: "bb.block.world.sand" },
			{ type: "block", id: "bb.block.world.ice" },
			{ type: "block", id: "bb.block.world.stone" },
			{ type: "block", id: "bb.block.world.cobblestone" },
			{ type: "block", id: "bb.block.world.wood" },
			{ type: "block", id: "bb.block.world.leaves" },
			{ type: "block", id: "bb.block.world.snow" },
		],
	},
};

const _BarrenDesertWorldTemplate: WorldTemplate = {
	title: "Barren Desert",
	description: "...?",
	thumbnailSrc: constants.images.IMAGES.BARREN_DESERT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: BARREN_DESERT_TERRAIN,
		defaultInventory: [...(MVRWorldTemplate.options.defaultInventory ?? [])],
		envPresetSettings: {
			sky: {
				cloudColor: "#f6e9d5",
				fogColor: "#fff4ad",
				skyColor: "#5ac8f6",
			},
			fog: {
				fogNear: 1,
				fogFar: 150,
			},
		},
	},
};

const TropicalWorldTemplate: WorldTemplate = {
	title: `Tropical Islands`,
	description: "A vast ocean world filled with jungle-covered islands",
	thumbnailSrc: constants.images.IMAGES.TROPICAL_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: TROPICAL_TERRAIN,
		defaultInventory: [
			{ type: "block", id: "bb.block.tropical.jungle-moss" },
			{ type: "block", id: "bb.block.tropical.brown-ceramic-pattern-a" },
			{ type: "block", id: "bb.block.tropical.checkered-tiles-1" },
			{ type: "block", id: "bb.block.tropical.brown-bricks-" },
			{ type: "block", id: "bb.block.tropical.fabric-pattern-d" },
			{ type: "block", id: "bb.block.tropical.big-green-leaves-" },
			{ type: "block", id: "bb.block.alienPlanet.bamboo-stalks" },
			{ type: "block", id: "bb.block.tropical.caution" },
			{ type: "block", id: "bb.block.tropical.mango" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 159,
			},
			sky: {
				fogy: 0,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#8bc4f8",
				skyColor: "#03578c",
				cloudSize: 0.29,
				cloudColor: "#fdf7e2",
				saturation: 2,
				groundColor: "#bb7831",
				toneMapping: ToneMapping.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 2.1,
			},
			light: {
				sunColor: "#ecbd89",
				sunIntensity: 1.1,
				hemiIntensity: 2.79,
				nightLuminosity: 0,
			},
			general: {
				hour: 7.9,
				azimuth: 329,
			},
		},
	},
};

const SnowyTundraWorldTemplate: WorldTemplate = {
	title: `Snowy Tundra`,
	description: "An arctic, snowy environment with pine trees and frozen rivers",
	thumbnailSrc: constants.images.IMAGES.SNOWY_TUNDRA_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: SNOWY_TUNDRA_TERRAIN,
		defaultInventory: [
			{ type: "block", id: "bb.block.tropical.snowy-grass" },
			{ type: "block", id: "bb.block.world.dirt" },
			{ type: "block", id: "bb.block.world.snow" },
			{ type: "block", id: "bb.block.world.stone" },
			{ type: "block", id: "bb.block.world.cobblestone" },
			{ type: "block", id: "bb.block.world.wood" },
			{ type: "block", id: "bb.block.tropical.pine-needles" },
			{ type: "block", id: "bb.block.tropical.snowy-pine-needles" },
			{ type: "block", id: "bb.block.world.ice" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 129,
				fogNear: 1,
			},
			sky: {
				fogy: 0.2,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#d1e9ff",
				skyColor: "#327e9f",
				cloudSize: 0,
				cloudColor: "#bdddff",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: ToneMapping.ACES,
				cloudDensity: 0.2,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 1.26,
			},
			light: {
				sunColor: "#ffffff",
				sunIntensity: 1.26,
				hemiIntensity: 2.79,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 8.4,
				azimuth: 21,
			},
		},
	},
};

const _DeepSpaceWorldTemplate: WorldTemplate = {
	title: `Deep Space`,
	description: "Where is taxi?",
	thumbnailSrc: constants.images.IMAGES.DEEP_SPACE_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: DEEP_SPACE_TERRAIN,
		envPresetSettings: {
			sky: {
				skyColor: "#000000",
				cloudCoverage: 0,
				fogColor: "#000000",
			},
			general: {
				hour: 0,
				azimuth: 0,
			},
			light: {
				nightLuminosity: 0,
			},
			fog: {
				fogNear: 200,
				fogFar: 200,
			},
		},
	},
};

const FantasyGrasslandsWorldTemplate: WorldTemplate = {
	title: `Fantasy Grasslands`,
	description: "A fantasy world with autumnal trees and grand mountains",
	thumbnailSrc: constants.images.IMAGES.FANTASY_GRASSLANDS_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: FANTASY_GRASSLANDS_TERRAIN,
		defaultInventory: [
			{ type: "block", id: "bb.block.kingdomAndWizardry.castle-brick-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.castle-block-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.bricks-and-mortar-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.farm-stone-bricks-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.castle-wooden-panels-4" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.reinforced-wood-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.burning-coals-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.rose-bush-1" },
			{ type: "block", id: "bb.block.kingdomAndWizardry.castle-window-1" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 200,
			},
			sky: {
				fogy: 0,
				haze: 0.1,
				step: 16,
				sample: 128,
				fogColor: "#81b8d9",
				skyColor: "#305464",
				cloudSize: 0.29,
				cloudColor: "#e0fffb",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: ToneMapping.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.56,
				cloudDistance: 0.64,
				toneMappingExposure: 3.1,
			},
			light: {
				sunColor: "#f5d6b2",
				sunIntensity: 0.4,
				hemiIntensity: 2.79,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 9.6,
				azimuth: 26,
			},
		},
	},
};

const WildWestDesertWorldTemplate: WorldTemplate = {
	title: `Wild West Desert`,
	description:
		"Vast desert landscape with the hot sun and cacti scattered across the sand",
	thumbnailSrc: constants.images.IMAGES.WILD_WEST_DESERT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: WILD_WEST_DESERT_TERRAIN,
		defaultInventory: [
			{ type: "block", id: "bb.block.wildwest.sand-rough" },
			{ type: "block", id: "bb.block.wildwest.sandstone" },
			{ type: "block", id: "bb.block.wildwest.cactus-v" },
			{ type: "block", id: "bb.block.wildwest.rough-stone-bricks" },
			{ type: "block", id: "bb.block.wildwest.planks-1" },
			{ type: "block", id: "bb.block.wildwest.lantern-1" },
			{ type: "block", id: "bb.block.wildwest.gold" },
			{ type: "block", id: "bb.block.wildwest.window1" },
			{ type: "block", id: "bb.block.wildwest.crate-2" },
		],
		envPresetSettings: {
			fog: {
				fogFar: 200,
				fogNear: 187,
			},
			sky: {
				fogy: 0,
				haze: 0,
				step: 16,
				sample: 128,
				fogColor: "#d9a27d",
				skyColor: "#0970f6",
				cloudSize: 0.29,
				cloudColor: "#fddfae",
				saturation: 1.3,
				groundColor: "#bb7831",
				toneMapping: ToneMapping.ACES,
				cloudDensity: 1,
				cloudCoverage: 0.5,
				cloudDistance: 0.64,
				toneMappingExposure: 2.7,
			},
			light: {
				sunColor: "#ffffff",
				sunIntensity: 0.9,
				hemiIntensity: 2.79,
				nightLuminosity: 0.81,
			},
			general: {
				hour: 16.5,
				azimuth: 21,
			},
		},
	},
};

export const Templates: WorldTemplate[] = [
	BlankWorldTemplate,
	MVRWorldTemplate,
	FantasyGrasslandsWorldTemplate,
	TropicalWorldTemplate,
	WildWestDesertWorldTemplate,
	SnowyTundraWorldTemplate,
];
