import { create } from "zustand";

import { useEngineStore } from "./bb";
import { AA_SETTING } from "@engine/base/util/Const.js";

export const useDebugStore = create((set, get) => ({
	open: false,
	benchmarkRunning: false,
	profilerRunning: false,
	benchmark: null,

	revision: null,
	hosting: true,
	seed: null,
	time: null,
	chunks: null,
	entityPosition: { x: 0, y: 0, z: 0 },
	gpu: null,
	fps: null,
	renderCalls: null,
	triangles: null,
	geometries: null,
	textures: null,
	mode: null,
	peers: null,
	ping: null,
	physXMemory: null,
	totalMemory: null,

	// Handlers
	setOpen: (open) => {
		set({ open });

		const { BB, client } = useEngineStore.getState().engine;
		BB[client].debug.setDebugEnabled(open);
	},
	setBenchmark: (benchmark, benchmarkRunning) =>
		set({ benchmark, benchmarkRunning }),
	toggleOpen: () => get().setOpen(!get().open),

	// Events
	init: ({ revision, buildTime, gpu }) => {
		set({ revision, buildTime, gpu });
	},
	setDebug: (settings) => {
		set(settings);
	},
	toggleBenchmark: () => {
		const { BB, client } = useEngineStore.getState().engine;

		if (get().benchmarkRunning) BB[client].debug.stopBenchmark();
		else BB[client].debug.startBenchmark();
	},
	startProfiler: () => {
		const { profiler } = useEngineStore.getState().engine;
		profiler.startRecording();
		set({ profilerRunning: true });
	},
	stopProfiler: () => {
		if (get().profilerRunning) {
			const { profiler } = useEngineStore.getState().engine;
			profiler.stopRecording();
			profiler.export();
			set({ profilerRunning: false });
		}
	},
	toggleOcclusionCulling: () => {
		const { BB, client } = useEngineStore.getState().engine;
		if (BB[client].settings.occlusionCulling == 0)
			BB[client].settings.occlusionCulling = 1;
		else BB[client].settings.occlusionCulling = 0;
	},
	toggleOcclusionCullingDebug: () => {
		const { BB, client } = useEngineStore.getState().engine;
		BB[client].settings.occlusionCullingDebug =
			!BB[client].settings.occlusionCullingDebug;
	},
	toggleLocomotionLogs: () => {
		const { BB, client } = useEngineStore.getState().engine;
		BB[client].settings.locomotionLogsEnabled =
			!BB[client].settings.locomotionLogsEnabled;
	},
	toggleDebugRendererMode: () => {
		const { BB, client } = useEngineStore.getState().engine;
		BB[client].debug.debugRendererMode =
			(BB[client].debug.debugRendererMode + 1) %
			BB[client].debug.numDebugRendererModes;
	},
	toggleZPrepass: () => {
		const { BB, client } = useEngineStore.getState().engine;
		BB[client].settings.zPrepassEnabled = !BB[client].settings.zPrepassEnabled;
	},
	toggleAASetting: () => {
		const { BB, client } = useEngineStore.getState().engine;
		switch (BB[client].settings.aaSetting) {
			case AA_SETTING.NO:
				BB[client].settings.aaSetting = AA_SETTING.MSAA;
				break;
			case AA_SETTING.MSAA:
				BB[client].settings.aaSetting = AA_SETTING.FXAA;
				break;
			case AA_SETTING.FXAA:
				BB[client].settings.aaSetting = AA_SETTING.NO;
				break;
		}
	},
}));

export default {
	useDebug: useDebugStore.getState,
};
