import type { IMultiplayerState } from "@jamango/client";
import { create } from "zustand";

type IMultiplayerStore = IMultiplayerState & {
	status: null | string;
	setStatus: (status: null | string) => void;
};

export const useMultiplayerStore = create<IMultiplayerStore>((set) => ({
	status: null,
	loading: null,
	isHosting: false,
	isPublicServer: false,
	isServer: true,
	serverID: null,
	peerID: null,
	hostname: null,
	players: [],
	servers: [],

	setStatus: (status) => set({ status }),
}));

export default {
	useMultiplayer: useMultiplayerStore.getState,
};
