import { create } from "zustand";
import { lib } from "jacy";
import { api, constants } from "rest-client";
import { GameMultiplayer, Router } from "@jamango/client";
import { Gear } from "@components/icons";

import { validateFilled } from "@lib/helpers/validateFilled";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";

import { useHubStore, useConfirmPromptStore } from "./dialogs";
import { Engine } from "./bb";
import { trackEvent, identifyUser } from "@lib/helpers/analytics/analyzeMe"; // Adjust the import path as necessary
import { Jacy } from "@jacy-client";
import { useAlertDialogStore } from "./dialogs/alert-dialog";
import { useJacyAvatarEditorStore } from "./jacy/avatar-editor";

export const LOADING_STATE = {
	idle: false,
	auth: "auth",
	login: "login",
	register: "register",
	updateProfile: "update-profile",
	updateEmail: "update-email",
	updateUsername: "update-username",
	updatePassword: "update-password",
};

export const useAuthDialogStore = create((set, get) => ({
	open: false,
	promise: lib.helpers.requests.createRequestPromise(),
	isAlert: false,
	resetWorld: true,
	error: null,
	didLogout: false,

	close: () => {
		set({ open: false, error: null, isAlert: false });
	},
	auth: () => {
		set({ open: "login", isAlert: true });
		return get().promise;
	},
	openLogin: (resetWorld = true) => {
		set({ open: "login", resetWorld });
	},
	openRegister: () => {
		set({ open: "register" });
	},
	setError: (error) => {
		set({ error });
	},
}));

export const useAuthUserStore = create((set) => ({
	authToken: null,
	hasPlayedTutorial: false,
	error: null,
	loading: LOADING_STATE.idle,

	// Handlers
	syncFeatureFlags() {
		const { DEBUG } = new Engine();

		const featureFlags = Jacy.state.user.featureFlags;

		if (
			globalEnv.NODE_ENV !== "production" ||
			featureFlags.includes(constants.experiments.FEATURE_FLAGS.CONSOLE_DEBUG)
		) {
			window.DEBUG = DEBUG;
			window.avatarEditorStore = useJacyAvatarEditorStore.getState();
		} else {
			window.DEBUG = undefined;
		}
	},
	// Events
	login: async ({ email, password }) => {
		set({ error: null, loading: LOADING_STATE.login });

		if (!validateFilled([email, password])) {
			set({
				error: "Email and password are required.",
				loading: LOADING_STATE.idle,
			});
			return;
		}

		let data = null;

		try {
			data = await api.auth.login({ email, password });

			await Jacy.setUser(data.user);
			set({ didLogout: false });
			// analytics
			identifyUser(data?.user?.id, {
				username: data?.user?.username,
				email: data?.user?.email,
			});
			trackEvent("event", "login");

			const { resetWorld, close, promise } = useAuthDialogStore.getState();

			if (resetWorld) {
				await GameMultiplayer.disconnect();
			}

			close();

			promise.resolve(data.user);
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return data;
	},
	tokenLogin: (payload) => {
		if (payload == null) return set({ authToken: null });

		set({ authToken: payload.authToken, username: payload.username });
	},
	logout: async () => {
		if (Router.isPlayPage()) {
			const confirmPrompt = await useConfirmPromptStore.getState().prompt;
			const description = Jacy.state.world.identifier
				? "You will be logged out of the game. Don't forget to save any unsaved progress of your current world."
				: "Any unsaved progress will be lost";

			const confirmed = await confirmPrompt({
				title: "Are you sure?",
				description,
				confirmText: "Yes, log out",
			});

			if (!confirmed) return confirmed;
		}

		localStorage.setItem("worldId", "");
		localStorage.setItem("serverId", "");

		useHubStore.getState().close();

		await GameMultiplayer.disconnect();

		try {
			await api.auth.logout();
			Router.navigate("/");
			set({ didLogout: true });
			await Jacy.setUser(null);
		} catch (ex) {
			console.error(ex);
		}
	},
	register: async ({
		accessCode,
		username,
		email,
		password,
		passwordConfirmation,
		hasFullAccess,
	}) => {
		if (!validateFilled([username, email, password, passwordConfirmation])) {
			set({
				error: "Username, email and password are required.",
				loading: LOADING_STATE.idle,
			});
			return;
		}

		set({ error: null, loading: LOADING_STATE.register });

		let newUser = null;

		try {
			const response = await api.auth.register({
				accessCode,
				username,
				email,
				password,
				passwordConfirmation,
				hasFullAccess,
			});

			newUser = response.user;
			await Jacy.setUser(newUser);

			trackEvent("event", "sign_up");

			const { resetWorld, close, promise } = useAuthDialogStore.getState();

			if (resetWorld) {
				await GameMultiplayer.disconnect();
			}

			close();
			promise.resolve(newUser);
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });

		return newUser;
	},
	updateProfile: async ({ username, firstName, lastName }) => {
		if (!validateFilled([username])) {
			set({ error: "Username is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updateProfile });

		let updatedUser = null;

		try {
			updatedUser = await api.auth.updateProfile({
				username,
				firstName,
				lastName,
			});
			await Jacy.setUser(updatedUser);
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return updatedUser;
	},
	updateEmail: async (email) => {
		if (!validateFilled([email])) {
			set({ error: "Email is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updateEmail });

		let updatedUser = null;

		try {
			updatedUser = await api.auth.updateEmail(email);
			await Jacy.setUser(updatedUser);
			useAlertDialogStore
				.getState()
				.setAlert({ title: "Email updated!", message: "" });
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return updatedUser;
	},
	updateUsername: async (username) => {
		if (!validateFilled([username])) {
			set({ error: "Username is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updateUsername });

		let updatedUser = null;

		try {
			updatedUser = await api.auth.updateUsername(username);
			await Jacy.setUser(updatedUser);
			useAlertDialogStore
				.getState()
				.setAlert({ title: "Username updated!", message: "" });
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return updatedUser;
	},
	updatePassword: async (password, passwordConfirmation) => {
		if (!validateFilled([password, passwordConfirmation])) {
			set({ error: "Password is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updatePassword });

		let updatedUser = null;

		try {
			updatedUser = await api.auth.updatePassword(password, passwordConfirmation);
			useAlertDialogStore
				.getState()
				.setAlert({ title: "Password updated!", message: "" });
		} catch (ex) {
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return updatedUser;
	},
	updateForgottenPassword: async (token, password, passwordConfirmation) => {
		if (!validateFilled([password, passwordConfirmation])) {
			set({ error: "Password is required.", loading: LOADING_STATE.idle });
			return;
		}

		set({ error: null, loading: LOADING_STATE.updatePassword });

		const updatedUser = null;

		try {
			const data = await api.auth.updateForgottenPassword(
				token,
				password,
				passwordConfirmation,
			);
			useAlertDialogStore.getState().setAlert({
				title: "Password changed!",
				message: "Redirecting you to the login page",
			});
			return data;
		} catch (ex) {
			console.log(ex);
			set({ error: formatErrorMessage(ex) });
		}

		set({ loading: LOADING_STATE.idle });
		return updatedUser;
	},
	setHasPlayedTutorial: async (hasPlayedTutorial) => {
		if (Jacy.state.user.isGuest) return null; // return if no auth user

		await api.auth.updateHasPlayedTutorial(hasPlayedTutorial);

		if (hasPlayedTutorial) trackEvent("event", "tutorial_begin");

		set({ hasPlayedTutorial });
	},
}));

export const PROFILE_TABS = [{ label: "General", icon: Gear }];

export const useProfileStore = create((set) => ({
	selectedTab: PROFILE_TABS[0].label,

	setSelectedTab: (selectedTab) => set({ selectedTab }),
}));

export default {
	useAuthDialog: useAuthDialogStore.getState,
	useAuthUser: useAuthUserStore.getState,
	useProfile: useProfileStore.getState,
};
