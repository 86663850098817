import type { IJacyContent } from "jacy";
import type { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyWorldActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	toggleAllowRemix(allowRemix: boolean) {
		try {
			const world = this.#state.world.get();
			world.allowRemix = allowRemix;
			const id = this.#state.world.set(world);
			useJacyRerenderStore.getState().forceRerenderWorld();
			this.#actions.updateChangeset(id, "update", { allow_remix: allowRemix });
		} catch (error) {
			this.#actions.setGeneralError(error);
		}
	}
}
