import type { IJacyContent, IWorldData, IWorldDataChangeset } from "jacy";
import type { JacyActions } from "../JacyActions";

import { lib, validations } from "jacy";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { Engine } from "@stores/bb";
import { useItemStore } from "@stores/hud/item";
import { api, constants } from "rest-client";
import { Jacy } from "@jacy-client";
import { useConfirmPromptStore } from "@stores/dialogs";
import { GameClient, Router } from "@jamango/client";
import { useInventoryStore } from "@stores/dialogs/inventory";

export class JacyWorldDataActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setName(name: string) {
		validations.world.name(name);
		const worldData = this.#state.worldData.get();
		worldData.name = name;
		const id = this.#state.worldData.set(worldData);
		useJacyRerenderStore.getState().forceRerenderWorldData();
		this.#actions.updateChangeset<IWorldDataChangeset>(id, "update", { name });
	}

	async setNameApi(name: string) {
		try {
			const identifier = this.#state.world.identifier;
			validations.world.name(name);
			await api.world.updateWorldName({ identifier, name });
			const worldData = this.#state.worldData.get();
			worldData.name = name;
			useJacyRerenderStore.getState().forceRerenderWorldData();
			this.#actions.setSuccess("Renamed world successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async reviewWorld() {
		const world = this.#state.world.get();
		const worldData = this.#state.worldData.get();

		if (!this.#state.thumbnail.url) {
			await useConfirmPromptStore.getState().prompt({
				title: "Warning",
				description: `You currently do not have a thumbnail for this world. Thumbnail is required when publishing a world.`,
				confirmText: "Okay",
				cancelText: "Cancel",
			});

			return;
		}

		try {
			if (!worldData.published) {
				let warningDescription = "";
				if (Jacy.hasChanges() || !world.identifier) {
					warningDescription +=
						"\n You currently have unsaved changes. We recommend saving a world first before publishing.";
				}

				if (this.#state.worldData.isExperimental) {
					warningDescription +=
						"\n You currently have experimental features turned on in this world, therefore this world will be not be appearing in the homepage due to its experimental features.";
				}

				if (this.#state.worldData.isExperimental || Jacy.hasChanges()) {
					const confirmed = await useConfirmPromptStore.getState().prompt({
						title: "Warning",
						description: warningDescription,
						confirmText: Jacy.hasChanges() ? "Save & Publish" : "Publish",
						cancelText: "Cancel",
					});
					if (!confirmed) return;
				}
			}

			if (Jacy.hasChanges()) {
				await GameClient.saveWorld();
			}

			this.#actions.setInfo(
				worldData.underReview ? "Publishing world..." : "Reviewing world...",
			);

			const updatedWorldData = (
				worldData.published
					? await api.world.unpublishWorld({ identifier: world.identifier })
					: await api.world.reviewWorld({ identifier: world.identifier })
			) as IWorldData;

			worldData.underReview = updatedWorldData.underReview;
			worldData.published = updatedWorldData.published;
			this.#state.worldData.set(worldData);
			useJacyRerenderStore.getState().forceRerenderWorldData();

			if (updatedWorldData.underReview) {
				if (useInventoryStore.getState().open) {
					this.#actions.setSuccess("World has been under review.");
				} else {
					this.#actions.setGeneralSuccess("World has been under review.");
				}
			} else if (!updatedWorldData.published && !updatedWorldData.underReview) {
				if (useInventoryStore.getState().open) {
					this.#actions.setSuccess("World has been unpublished.");
				} else {
					this.#actions.setGeneralSuccess("World has been unpublished.");
				}
			}
		} catch (error) {
			this.#actions.setGeneralError(error);
		}
	}

	async deleteWorld() {
		const identifier = this.#state.world.identifier;
		const name = this.#state.worldData.name;

		if (lib.helpers.general.isNullish(identifier)) {
			this.#actions.setError("Cannot delete an unsaved world");
			return;
		}

		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"Deleting this world is irreversible. It will delete the associated blocks, skybox, and texture pack(s) that are not used in other worlds.",
			confirmText: "Yes, delete the world",
			prompt: `delete ${name}`,
		});

		if (!confirmed) return;

		this.#actions.setInfo("Deleting world....");

		try {
			await api.world.deleteWorld({ identifier });
			Router.navigate("/");
			this.#actions.setSuccess("World has been deleted.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	toggleMode(mode: string, isInitialLoad: boolean = false) {
		try {
			const { BB } = new Engine();
			BB.mode = mode;

			if (mode === constants.world.WORLD_MODE.CREATOR && !isInitialLoad) {
				const equippedItem = useItemStore.getState().equippedItem;
				if (equippedItem && !constants.selector.TOOLS.includes(equippedItem))
					useItemStore.getState().cycleTool();
			}

			const id = this.#state.worldData.updateMode(mode);
			useJacyRerenderStore.getState().forceRerenderWorldData();

			if (!isInitialLoad) {
				this.#actions.updateChangeset<IWorldDataChangeset>(id, "update", {
					mode,
				});
			}
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	toggleExperiments(experiment: string, enabled: boolean) {
		try {
			const { id, experiments } = this.#state.worldData.updateExperiments(
				experiment,
				enabled,
			);
			useJacyRerenderStore.getState().forceRerenderWorldData();
			this.#actions.setSuccess(
				`Successfully ${enabled ? "added" : "removed"} experiment: ${experiment}`,
			);
			this.#actions.updateChangeset<IWorldDataChangeset>(id, "update", {
				experiments,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
