import type { IAssetKey, IAvatarChangeset, IJacyContent } from "jacy";
import { AvatarType, JacyCore, lib } from "jacy";
import type { JacyActions } from "../JacyActions";
import { Engine } from "@stores/bb";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { trackEvent } from "@lib/helpers/analytics/analyzeMe";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";
import { api } from "rest-client";
import { useJacyUserStore } from "@stores/jacy/user";
import { Jacy } from "@jacy-client";

export class JacyAvatarActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];
	editorLoadedPromise: ReturnType<
		typeof lib.helpers.requests.createRequestPromise<boolean>
	>;

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
		this.editorLoadedPromise = lib.helpers.requests.createRequestPromise<boolean>();
	}

	setEditorAsLoaded() {
		this.editorLoadedPromise.resolve(true);
		useJacyAvatarEditorStore.getState().setLoading(false);
	}

	newAvatar(isCharacterEditor?: boolean) {
		try {
			if (!this.#state.avatars.canSave(isCharacterEditor)) {
				throw new Error("User is not allowed to create a new avatar.");
			}

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: isCharacterEditor ? "InventoryNewCharacterAvatar" : "HubNewUserAvatar",
			});

			useJacyAvatarEditorStore.getState().newAvatar(isCharacterEditor);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async createAvatar() {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor =
				useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (!isCharacterEditor) {
				throw new Error("Must be in the character editor to use this API.");
			}

			const newAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!newAvatar.thumbnail || !newAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			const avatar = this.#state.avatars.create(
				AvatarType.CHARACTER,
				newAvatar.config,
				newAvatar.thumbnail,
				newAvatar.displayPhoto,
				true,
			);

			trackEvent("event", "save_new_avatar", { avatar_id: avatar.pk });

			useJacyCharacterEditorStore.getState().setAvatar(avatar.pk);
			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: "InventoryCreateCharacterAvatar",
			});

			this.#actions.setSuccess(`Avatar "${avatar.name}" created successfully.`);

			this.#actions.updateChangeset<IAvatarChangeset>(avatar.pk, "create", {
				name: avatar.name,
				avatarType: avatar.avatarType,
				thumbnail: newAvatar.thumbnail,
				displayPhoto: newAvatar.displayPhoto,
				config: avatar.config,
			});

			return avatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);
			this.#actions.setError(error);
		}
	}

	async createUserAvatarAPI() {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor =
				useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (isCharacterEditor) {
				throw new Error("Must be in the avatar editor to use this API.");
			}

			const newAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!newAvatar.thumbnail || !newAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			const avatar = this.#state.avatars.create(
				AvatarType.USER_AVATAR,
				newAvatar.config,
				newAvatar.thumbnail,
				newAvatar.displayPhoto,
				false,
			);

			try {
				const createdAvatar = await api.avatars.createUserAvatar({
					identifier: avatar.id,
					name: avatar.name,
					avatarType: avatar.avatarType,
					thumbnail: newAvatar.thumbnail,
					displayPhoto: newAvatar.displayPhoto,
					config: avatar.config,
				});

				this.#state.avatars.set(createdAvatar, false);
				useJacyUserStore.setState({ displayPhoto: createdAvatar.displayPhoto });
			} catch (error) {
				this.#state.avatars.delete(avatar.pk, false);
				throw error;
			}

			trackEvent("event", "save_new_avatar", { avatar_id: avatar.pk });

			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "HubCreateUserAvatar" });

			this.#actions.setSuccess(`Avatar "${avatar.name}" created successfully.`);

			this.loadAvatarInGame();

			return avatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);

			this.#actions.setError(error);
		}
	}

	editAvatar(pk: IAssetKey, isCharacterEditor?: boolean) {
		try {
			if (!this.#state.avatars.canEdit(pk, isCharacterEditor)) {
				throw new Error("User is not allowed to edit the avatar.");
			}

			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				this.#actions.setError("Avatar not found");
				return;
			}

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: isCharacterEditor
					? "InventoryEditCharacterAvatar"
					: "HubEditUserAvatar",
			});

			useJacyAvatarEditorStore.getState().editAvatar(avatar, isCharacterEditor);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateAvatar() {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor =
				useJacyAvatarEditorStore.getState().isCharacterEditor;

			if (!isCharacterEditor) {
				throw new Error("Must be in the character editor to use this API.");
			}

			const updatedAvatar = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!updatedAvatar.thumbnail || !updatedAvatar.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			if (!updatedAvatar.pk) {
				throw new Error("Avatar id is not set.");
			}

			const avatar = this.#state.avatars.update(
				updatedAvatar.pk,
				updatedAvatar.config,
				updatedAvatar.thumbnail,
				updatedAvatar.displayPhoto,
				true,
			);

			trackEvent("event", "update_avatar", { avatar_id: avatar.pk });

			useJacyCharacterEditorStore.getState().setAvatar(avatar.pk);
			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: "InventoryCreateCharacterAvatar",
			});

			this.#actions.setSuccess(`Avatar "${avatar.name}" updated successfully.`);

			this.#actions.updateChangeset<IAvatarChangeset>(avatar.pk, "update", {
				name: avatar.name,
				avatarType: avatar.avatarType,
				thumbnail: updatedAvatar.thumbnail,
				displayPhoto: updatedAvatar.displayPhoto,
				config: avatar.config,
			});

			return avatar.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateUserAvatarAPI() {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			useJacyAvatarEditorStore.getState().setLoading(true);

			const isCharacterEditor =
				useJacyAvatarEditorStore.getState().isCharacterEditor;
			const avatarToUpdate = await useJacyAvatarEditorStore.getState().getAvatar();

			if (!avatarToUpdate.thumbnail || !avatarToUpdate.displayPhoto) {
				throw new Error("Failed to create avatar thumbnails.");
			}

			if (!avatarToUpdate.pk) {
				throw new Error("Avatar id is not set.");
			}

			let avatar = this.#state.avatars.get(avatarToUpdate.pk);

			if (!avatar) {
				throw new Error("Avatar not found");
			}

			const updatedAvatar = await api.avatars.updateUserAvatar({
				identifier: avatar.id,
				name: avatar.name,
				avatarType: avatar.avatarType,
				thumbnail: avatarToUpdate.thumbnail,
				displayPhoto: avatarToUpdate.displayPhoto,
				config: avatarToUpdate.config,
				isCharacterEditor,
			});

			avatar = this.#state.avatars.update(
				updatedAvatar.pk,
				updatedAvatar.config,
				updatedAvatar.thumbnail,
				updatedAvatar.displayPhoto,
				isCharacterEditor,
			);

			trackEvent("event", "update_avatar", { avatar_id: avatar.pk });

			useJacyRerenderStore.getState().forceRerenderAvatars();
			useJacyAvatarEditorStore.getState().close();
			useJacyAvatarEditorStore.getState().setLoading(false);

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "HubCreateUserAvatar" });

			this.#actions.setSuccess(`Avatar "${avatar.name}" updated successfully.`);

			if (isCharacterEditor) {
				useJacyCharacterEditorStore.getState().setAvatar(avatar.pk);
			}

			const userAvatar = this.#state.user.getAvatar();
			if (userAvatar && userAvatar.pk === avatar.pk) {
				useJacyUserStore.setState({ displayPhoto: this.#state.user.displayPhoto });
				this.loadAvatarInGame();
			}

			return avatar.pk;
		} catch (error) {
			useJacyAvatarEditorStore.getState().setLoading(false);
			this.#actions.setError(error);
		}
	}

	updateAvatarName(pk: IAssetKey, name: string) {
		try {
			this.#state.avatars.setName(pk, name, true);

			this.#actions.setSuccess(`Avatar "${name}" has been updated successfully.`);

			this.#actions.updateChangeset<IAvatarChangeset>(pk, "update", {
				name,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async updateUserAvatarNameAPI(pk: IAssetKey, name: string) {
		try {
			const avatar = this.#state.avatars.setName(pk, name, false);
			await api.avatars.updateUserAvatarName(avatar.id, name);

			this.#actions.setSuccess(`Avatar "${name}" has been updated successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async setAsUserAvatarAPI(pk: IAssetKey) {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			const id = JacyCore.getIdentifier(pk);

			await api.avatars.setAsUserAvatar(id);

			const avatar = this.#state.avatars.setUserAvatar(pk);
			const { BB } = new Engine();

			const playerObj: any = {};

			Object.entries(BB.world.game.players)?.forEach(
				([peerID, player]: [string, any]) => {
					if (player.playerID === user.playerId) {
						playerObj[peerID] = { ...player, defaultAvatar: avatar };
					} else {
						playerObj[peerID] = player;
					}
				},
			);

			BB.world.game.setPlayers(playerObj);

			useJacyUserStore.setState({ displayPhoto: this.#state.user.displayPhoto });
			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess(
				`Avatar "${avatar.name}" has been selected successfully.`,
			);

			this.loadAvatarInGame();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	restoreAvatar(pk: IAssetKey) {
		try {
			this.#state.avatars.restore(pk);

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess("Avatar has been restored successfully.");

			this.#actions.updateChangeset<IAvatarChangeset>(pk, "update", {
				isDeleted: false,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteAvatar(pk: IAssetKey) {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				throw new Error("Avatar not found");
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Avatar",
				description: `Are you sure you want to delete "${avatar.name}" avatar?`,
				confirmText: "Yes, delete this avatar.",
			});

			if (!confirmed) return;

			this.#state.avatars.delete(pk, true);

			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess(
				`Avatar "${avatar.name}" has been deleted successfully.`,
			);

			this.#actions.updateChangeset<IAvatarChangeset>(pk, "update", {
				isDeleted: true,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteUserAvatarAPI(pk: IAssetKey) {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			const avatar = this.#state.avatars.get(pk);

			if (!avatar) {
				throw new Error("Avatar not found");
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Avatar",
				description: `Are you sure you want to delete "${avatar.name}" avatar?`,
				confirmText: "Yes, delete this avatar.",
			});

			if (!confirmed) return;

			const currentUserAvatar = this.#state.user.getAvatar();
			const nextAvatar =
				currentUserAvatar && currentUserAvatar.pk === pk
					? this.#state.avatars.getNextAvatar(pk)
					: undefined;

			await api.avatars.deleteUserAvatar(avatar.id, nextAvatar?.id);

			this.#state.avatars.delete(pk, false, nextAvatar);

			Jacy.refreshUserState();
			useJacyRerenderStore.getState().forceRerenderAvatars();

			this.#actions.setSuccess("Avatar has been deleted successfully.");

			this.loadAvatarInGame();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async loadAvatarInGame() {
		const config = this.#state.user.getAvatarConfig();
		const avatarObject = this.#state.user.getAvatarObject();
		if (!config || !avatarObject) return;

		const { gbi } = new Engine();
		if (!gbi) return;
		await gbi.mods.updatePlayerAvatar(config, avatarObject);
	}
}
