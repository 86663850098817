import type { BlockTypeName, TerrainObject } from "jacy";

const BLOCK_CUBE = 15;
const BLOCK_AIR = 0;

const LEAVES_BLOCK: BlockTypeName = "bb.block.world.leaves";

const getLog = (height: number) => {
	const result: TerrainObject = [];
	for (let y = 0; y < height; y++) {
		result.push([0, y, 0, BLOCK_CUBE, LEAVES_BLOCK]);
	}
	return result;
};

const getLeaf = (
	yOffset: number,
	height: number,
	xOffset: number,
	zOffset: number,
) => {
	const result: TerrainObject = [];
	const max = yOffset + height;
	for (let y = yOffset; y < max; y++) {
		result.push([xOffset, y, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
	}

	if (xOffset !== 0) {
		const xDir = Math.sign(xOffset);
		for (let x = xOffset - xDir; x !== 0; x -= xDir) {
			result.push([x, yOffset, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	if (zOffset !== 0) {
		const zDir = Math.sign(zOffset);
		for (let z = zOffset - zDir; z !== 0; z -= zDir) {
			result.push([xOffset, yOffset, z, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	return result;
};

export const CactusesObjects: TerrainObject[] = [
	[...getLog(5), ...getLeaf(1, 3, 2, 0)],
	[...getLog(6), ...getLeaf(1, 3, -2, 0), ...getLeaf(2, 3, 0, 2)],
	[
		...getLog(7),
		...getLeaf(1, 3, -2, 0),
		...getLeaf(2, 3, 0, -2),
		...getLeaf(3, 4, 2, 0),
	],
];

export const SPACE_BLOCK_NAME: BlockTypeName = "bb.block.spaceStation.metal-grate-2";

export const createPlatform = (
	pivotX: number,
	pivotY: number,
	pivotZ: number,
	width: number,
	height: number,
	depth: number,
	blockName: BlockTypeName,
) => {
	const blocks: TerrainObject = [];

	for (let y = 0; y < height; y++) {
		for (let z = 0; z < depth; z++) {
			for (let x = 0; x < width; x++) {
				blocks.push([pivotX + x, pivotY + y, pivotZ + z, BLOCK_CUBE, blockName]);
			}
		}
	}

	return blocks;
};

export const SIMPLE_TREE: TerrainObject = [
	[0, 0, 0, 101, "bb.block.world.wood"],
	[0, 0, 1, 15, "bb.block.world.wood"],
	[0, 0, 2, 26, "bb.block.world.wood"],
	[1, 0, 1, 74, "bb.block.world.wood"],
	[0, 1, 1, 15, "bb.block.world.wood"],
	[0, 2, 1, 15, "bb.block.world.wood"],
	[-1, 3, 0, 15, "bb.block.world.leaves"],
	[0, 3, 0, 15, "bb.block.world.leaves"],
	[1, 3, 0, 15, "bb.block.world.leaves"],
	[-1, 3, 1, 15, "bb.block.world.leaves"],
	[0, 3, 1, 15, "bb.block.world.wood"],
	[1, 3, 1, 15, "bb.block.world.leaves"],
	[-1, 3, 2, 15, "bb.block.world.leaves"],
	[0, 3, 2, 15, "bb.block.world.leaves"],
	[1, 3, 2, 15, "bb.block.world.leaves"],
	[-2, 3, -1, 84, "bb.block.world.leaves"],
	[-1, 3, -1, 85, "bb.block.world.leaves"],
	[0, 3, -1, 85, "bb.block.world.leaves"],
	[1, 3, -1, 85, "bb.block.world.leaves"],
	[2, 3, -1, 81, "bb.block.world.leaves"],
	[-2, 3, 0, 92, "bb.block.world.leaves"],
	[2, 3, 0, 83, "bb.block.world.leaves"],
	[-2, 3, 1, 92, "bb.block.world.leaves"],
	[2, 3, 1, 83, "bb.block.world.leaves"],
	[-2, 3, 2, 92, "bb.block.world.leaves"],
	[2, 3, 2, 83, "bb.block.world.leaves"],
	[-2, 3, 3, 88, "bb.block.world.leaves"],
	[-1, 3, 3, 90, "bb.block.world.leaves"],
	[0, 3, 3, 90, "bb.block.world.leaves"],
	[1, 3, 3, 90, "bb.block.world.leaves"],
	[2, 3, 3, 82, "bb.block.world.leaves"],
	[-2, 4, -1, 108, "bb.block.world.leaves"],
	[-1, 4, -1, 15, "bb.block.world.leaves"],
	[0, 4, -1, 15, "bb.block.world.leaves"],
	[1, 4, -1, 15, "bb.block.world.leaves"],
	[2, 4, -1, 99, "bb.block.world.leaves"],
	[-2, 4, 0, 15, "bb.block.world.leaves"],
	[-1, 4, 0, 15, "bb.block.world.leaves"],
	[0, 4, 0, 15, "bb.block.world.leaves"],
	[1, 4, 0, 15, "bb.block.world.leaves"],
	[2, 4, 0, 15, "bb.block.world.leaves"],
	[-2, 4, 1, 15, "bb.block.world.leaves"],
	[-1, 4, 1, 15, "bb.block.world.leaves"],
	[0, 4, 1, 15, "bb.block.world.wood"],
	[1, 4, 1, 15, "bb.block.world.leaves"],
	[2, 4, 1, 15, "bb.block.world.leaves"],
	[-2, 4, 2, 15, "bb.block.world.leaves"],
	[-1, 4, 2, 15, "bb.block.world.leaves"],
	[0, 4, 2, 15, "bb.block.world.leaves"],
	[1, 4, 2, 15, "bb.block.world.leaves"],
	[2, 4, 2, 15, "bb.block.world.leaves"],
	[-2, 4, 3, 28, "bb.block.world.leaves"],
	[-1, 4, 3, 15, "bb.block.world.leaves"],
	[0, 4, 3, 15, "bb.block.world.leaves"],
	[1, 4, 3, 15, "bb.block.world.leaves"],
	[2, 4, 3, 19, "bb.block.world.leaves"],
	[-2, 5, -1, 108, "bb.block.world.leaves"],
	[-1, 5, -1, 15, "bb.block.world.leaves"],
	[0, 5, -1, 15, "bb.block.world.leaves"],
	[1, 5, -1, 15, "bb.block.world.leaves"],
	[2, 5, -1, 99, "bb.block.world.leaves"],
	[-2, 5, 0, 15, "bb.block.world.leaves"],
	[-1, 5, 0, 15, "bb.block.world.leaves"],
	[0, 5, 0, 15, "bb.block.world.leaves"],
	[1, 5, 0, 15, "bb.block.world.leaves"],
	[2, 5, 0, 15, "bb.block.world.leaves"],
	[-2, 5, 1, 15, "bb.block.world.leaves"],
	[-1, 5, 1, 15, "bb.block.world.leaves"],
	[0, 5, 1, 15, "bb.block.world.leaves"],
	[1, 5, 1, 15, "bb.block.world.leaves"],
	[2, 5, 1, 15, "bb.block.world.leaves"],
	[-2, 5, 2, 15, "bb.block.world.leaves"],
	[-1, 5, 2, 15, "bb.block.world.leaves"],
	[0, 5, 2, 15, "bb.block.world.leaves"],
	[1, 5, 2, 15, "bb.block.world.leaves"],
	[2, 5, 2, 15, "bb.block.world.leaves"],
	[-2, 5, 3, 28, "bb.block.world.leaves"],
	[-1, 5, 3, 15, "bb.block.world.leaves"],
	[0, 5, 3, 15, "bb.block.world.leaves"],
	[1, 5, 3, 15, "bb.block.world.leaves"],
	[2, 5, 3, 19, "bb.block.world.leaves"],
	[-2, 6, -1, 40, "bb.block.world.leaves"],
	[-1, 6, -1, 42, "bb.block.world.leaves"],
	[0, 6, -1, 42, "bb.block.world.leaves"],
	[1, 6, -1, 42, "bb.block.world.leaves"],
	[2, 6, -1, 34, "bb.block.world.leaves"],
	[-2, 6, 0, 44, "bb.block.world.leaves"],
	[-1, 6, 0, 15, "bb.block.world.leaves"],
	[0, 6, 0, 15, "bb.block.world.leaves"],
	[1, 6, 0, 15, "bb.block.world.leaves"],
	[2, 6, 0, 35, "bb.block.world.leaves"],
	[-2, 6, 1, 44, "bb.block.world.leaves"],
	[-1, 6, 1, 15, "bb.block.world.leaves"],
	[0, 6, 1, 15, "bb.block.world.leaves"],
	[1, 6, 1, 15, "bb.block.world.leaves"],
	[2, 6, 1, 35, "bb.block.world.leaves"],
	[-2, 6, 2, 44, "bb.block.world.leaves"],
	[-1, 6, 2, 15, "bb.block.world.leaves"],
	[0, 6, 2, 15, "bb.block.world.leaves"],
	[1, 6, 2, 15, "bb.block.world.leaves"],
	[2, 6, 2, 35, "bb.block.world.leaves"],
	[-2, 6, 3, 36, "bb.block.world.leaves"],
	[-1, 6, 3, 37, "bb.block.world.leaves"],
	[0, 6, 3, 37, "bb.block.world.leaves"],
	[1, 6, 3, 37, "bb.block.world.leaves"],
	[2, 6, 3, 33, "bb.block.world.leaves"],
	[-1, 7, 0, 40, "bb.block.world.leaves"],
	[0, 7, 0, 42, "bb.block.world.leaves"],
	[1, 7, 0, 34, "bb.block.world.leaves"],
	[-1, 7, 1, 44, "bb.block.world.leaves"],
	[0, 7, 1, 15, "bb.block.world.leaves"],
	[1, 7, 1, 35, "bb.block.world.leaves"],
	[-1, 7, 2, 36, "bb.block.world.leaves"],
	[0, 7, 2, 37, "bb.block.world.leaves"],
	[1, 7, 2, 33, "bb.block.world.leaves"],
];

export const CANOPY_TREE: TerrainObject = [
	[0, 0, 0, 58, "bb.block.tropical.jungle-mossy-log"],
	[1, 0, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[2, 0, 0, 74, "bb.block.tropical.jungle-mossy-log"],
	[1, 0, -1, 101, "bb.block.tropical.jungle-mossy-log"],
	[1, 0, 1, 26, "bb.block.tropical.jungle-mossy-log"],
	[1, 1, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[1, 2, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[1, 3, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[1, 4, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[1, 5, 0, 15, "bb.block.tropical.canopy-tree-log"],
	[1, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 8, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 8, -1, 44, "bb.block.tropical.canopy-tree-leaves"],
	[1, 8, -1, 43, "bb.block.tropical.canopy-tree-leaves"],
	[2, 8, -1, 34, "bb.block.tropical.canopy-tree-leaves"],
	[0, 8, 0, 44, "bb.block.tropical.canopy-tree-leaves"],
	[2, 8, 0, 35, "bb.block.tropical.canopy-tree-leaves"],
	[0, 8, 1, 36, "bb.block.tropical.canopy-tree-leaves"],
	[1, 8, 1, 37, "bb.block.tropical.canopy-tree-leaves"],
	[2, 8, 1, 33, "bb.block.tropical.canopy-tree-leaves"],
	[0, 8, -2, 40, "bb.block.tropical.canopy-tree-leaves"],
	[1, 8, -2, 34, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, -3, 40, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, -3, 42, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, -3, 34, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, -2, 62, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, -2, 103, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, 2, 30, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, 2, 78, "bb.block.tropical.canopy-tree-leaves"],
	[0, 7, 3, 36, "bb.block.tropical.canopy-tree-leaves"],
	[1, 7, 3, 37, "bb.block.tropical.canopy-tree-leaves"],
	[2, 7, 3, 33, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 7, -1, 40, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 7, -1, 62, "bb.block.tropical.canopy-tree-leaves"],
	[3, 7, -1, 43, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 7, 0, 44, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 7, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 7, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 7, 1, 50, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 7, 1, 59, "bb.block.tropical.canopy-tree-leaves"],
	[3, 7, 1, 78, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 7, 2, 0, "bb.block.world.grass"],
	[-1, 7, 2, 24, "bb.block.tropical.canopy-tree-leaves"],
	[3, 7, 2, 33, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 7, -2, 56, "bb.block.tropical.canopy-tree-leaves"],
	[3, 7, -2, 97, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, -3, 180, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, -3, 110, "bb.block.tropical.canopy-tree-leaves"],
	[1, 5, -3, 107, "bb.block.tropical.canopy-tree-leaves"],
	[2, 5, -3, 107, "bb.block.tropical.canopy-tree-leaves"],
	[3, 5, -3, 193, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, -2, 94, "bb.block.tropical.canopy-tree-leaves"],
	[1, 5, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 5, -2, 91, "bb.block.tropical.canopy-tree-leaves"],
	[3, 5, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, -1, 107, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, -1, 0, "bb.block.world.grass"],
	[1, 5, -1, 37, "bb.block.tropical.canopy-tree-log"],
	[2, 5, -1, 0, "bb.block.world.grass"],
	[3, 5, -1, 110, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, 0, 35, "bb.block.tropical.canopy-tree-log"],
	[2, 5, 0, 44, "bb.block.tropical.canopy-tree-log"],
	[3, 5, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, 1, 91, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, 1, 0, "bb.block.world.grass"],
	[1, 5, 1, 42, "bb.block.tropical.canopy-tree-log"],
	[2, 5, 1, 0, "bb.block.tropical.canopy-tree-leaves"],
	[3, 5, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, 2, 61, "bb.block.tropical.canopy-tree-leaves"],
	[1, 5, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 5, 2, 87, "bb.block.tropical.canopy-tree-leaves"],
	[3, 5, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 5, 3, 177, "bb.block.tropical.canopy-tree-leaves"],
	[0, 5, 3, 29, "bb.block.tropical.canopy-tree-leaves"],
	[1, 5, 3, 23, "bb.block.tropical.canopy-tree-leaves"],
	[2, 5, 3, 77, "bb.block.tropical.canopy-tree-leaves"],
	[3, 5, 3, 196, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, -3, 228, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, -3, 109, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, -3, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, -3, 103, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, -3, 225, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, -2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, -1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, 1, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, 2, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 6, 3, 152, "bb.block.tropical.canopy-tree-leaves"],
	[0, 6, 3, 30, "bb.block.tropical.canopy-tree-leaves"],
	[1, 6, 3, 15, "bb.block.tropical.canopy-tree-leaves"],
	[2, 6, 3, 27, "bb.block.tropical.canopy-tree-leaves"],
	[3, 6, 3, 146, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 5, -2, 232, "bb.block.tropical.canopy-tree-leaves"],
	[4, 5, -2, 226, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 5, -1, 61, "bb.block.tropical.canopy-tree-leaves"],
	[4, 5, -1, 71, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 5, 0, 61, "bb.block.tropical.canopy-tree-leaves"],
	[4, 5, 0, 71, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 5, 1, 55, "bb.block.tropical.canopy-tree-leaves"],
	[4, 5, 1, 77, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 5, 2, 148, "bb.block.tropical.canopy-tree-leaves"],
	[4, 5, 2, 145, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 6, -2, 184, "bb.block.tropical.canopy-tree-leaves"],
	[4, 6, -2, 194, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 6, -1, 62, "bb.block.tropical.canopy-tree-leaves"],
	[4, 6, -1, 75, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[4, 6, 0, 15, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 6, 1, 45, "bb.block.tropical.canopy-tree-leaves"],
	[4, 6, 1, 78, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 6, 2, 178, "bb.block.tropical.canopy-tree-leaves"],
	[4, 6, 2, 200, "bb.block.tropical.canopy-tree-leaves"],
	[4, 7, -1, 34, "bb.block.tropical.canopy-tree-leaves"],
	[4, 7, 0, 35, "bb.block.tropical.canopy-tree-leaves"],
	[4, 7, 1, 33, "bb.block.tropical.canopy-tree-leaves"],
	[3, 4, 2, 84, "bb.block.tropical.canopy-tree-leaves"],
	[3, 4, -1, 84, "bb.block.tropical.canopy-tree-leaves"],
	[4, 4, -1, 81, "bb.block.tropical.canopy-tree-leaves"],
	[3, 4, 0, 84, "bb.block.tropical.canopy-tree-leaves"],
	[4, 4, 0, 81, "bb.block.tropical.canopy-tree-leaves"],
	[3, 4, 1, 88, "bb.block.tropical.canopy-tree-leaves"],
	[4, 4, 1, 82, "bb.block.tropical.canopy-tree-leaves"],
	[3, 4, -2, 88, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 4, -2, 82, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 4, -1, 84, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 4, -1, 81, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 4, 0, 84, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 4, 0, 81, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 4, 1, 88, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 4, 1, 82, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 4, 2, 0, "bb.block.world.grass"],
	[-1, 4, 2, 81, "bb.block.tropical.canopy-tree-leaves"],
	[0, 4, 2, 84, "bb.block.tropical.canopy-tree-leaves"],
	[1, 4, 2, 81, "bb.block.tropical.canopy-tree-leaves"],
	[2, 4, 2, 81, "bb.block.tropical.canopy-tree-leaves"],
	[0, 4, 3, 20, "bb.block.tropical.canopy-tree-leaves"],
	[1, 4, 3, 17, "bb.block.tropical.canopy-tree-leaves"],
	[2, 4, 3, 82, "bb.block.tropical.canopy-tree-leaves"],
	[0, 4, 0, 53, "bb.block.tropical.canopy-tree-log"],
	[2, 4, 0, 69, "bb.block.tropical.canopy-tree-log"],
	[1, 4, -1, 106, "bb.block.tropical.canopy-tree-log"],
	[1, 4, 1, 21, "bb.block.tropical.canopy-tree-log"],
	[0, 4, -3, 84, "bb.block.tropical.canopy-tree-leaves"],
	[1, 4, -3, 81, "bb.block.tropical.canopy-tree-leaves"],
	[2, 4, -3, 81, "bb.block.tropical.canopy-tree-leaves"],
	[0, 4, -2, 88, "bb.block.tropical.canopy-tree-leaves"],
	[1, 4, -2, 82, "bb.block.tropical.canopy-tree-leaves"],
	[2, 4, -2, 82, "bb.block.tropical.canopy-tree-leaves"],
];

export const TALL_PALM_TREE: TerrainObject = [
	[0, 0, 0, 168, "bb.block.tropical.jungle-moss"],
	[1, 0, 0, 15, "bb.block.tropical.palm-log"],
	[2, 0, 0, 162, "bb.block.tropical.jungle-moss"],
	[0, 0, 1, 15, "bb.block.tropical.palm-log"],
	[1, 0, 1, 15, "bb.block.tropical.palm-log"],
	[2, 0, 1, 15, "bb.block.tropical.palm-log"],
	[0, 0, 2, 164, "bb.block.tropical.jungle-moss"],
	[1, 0, 2, 15, "bb.block.tropical.palm-log"],
	[2, 0, 2, 161, "bb.block.tropical.jungle-moss"],
	[0, 1, 1, 58, "bb.block.tropical.palm-log"],
	[1, 1, 1, 15, "bb.block.tropical.palm-log"],
	[2, 1, 1, 74, "bb.block.tropical.palm-log"],
	[1, 1, 0, 101, "bb.block.tropical.palm-log"],
	[1, 1, 2, 26, "bb.block.tropical.palm-log"],
	[1, 2, 1, 15, "bb.block.tropical.palm-log"],
	[1, 3, 1, 15, "bb.block.tropical.palm-log"],
	[1, 4, 1, 15, "bb.block.tropical.palm-log"],
	[1, 5, 1, 15, "bb.block.tropical.palm-log"],
	[1, 6, 1, 15, "bb.block.tropical.palm-log"],
	[1, 7, 1, 15, "bb.block.tropical.palm-log"],
	[1, 8, 1, 15, "bb.block.tropical.palm-log"],
	[1, 9, 1, 15, "bb.block.tropical.palm-leaf"],
	[0, 9, 1, 53, "bb.block.tropical.palm-leaf"],
	[0, 10, 1, 35, "bb.block.tropical.palm-leaf"],
	[-2, 10, 1, 58, "bb.block.tropical.palm-leaf"],
	[-1, 10, 1, 15, "bb.block.tropical.palm-leaf"],
	[-3, 9, 1, 58, "bb.block.tropical.palm-leaf"],
	[-2, 9, 1, 83, "bb.block.tropical.palm-leaf"],
	[-3, 6, 1, 92, "bb.block.tropical.palm-leaf"],
	[-3, 7, 1, 15, "bb.block.tropical.palm-leaf"],
	[-3, 8, 1, 15, "bb.block.tropical.palm-leaf"],
	[1, 9, 0, 106, "bb.block.tropical.palm-leaf"],
	[1, 10, 0, 37, "bb.block.tropical.palm-leaf"],
	[1, 10, -2, 101, "bb.block.tropical.palm-leaf"],
	[1, 10, -1, 15, "bb.block.tropical.palm-leaf"],
	[1, 9, -3, 101, "bb.block.tropical.palm-leaf"],
	[1, 9, -2, 90, "bb.block.tropical.palm-leaf"],
	[1, 7, -3, 85, "bb.block.tropical.palm-leaf"],
	[1, 8, -3, 15, "bb.block.tropical.palm-leaf"],
	[2, 9, 1, 69, "bb.block.tropical.palm-leaf"],
	[3, 9, 1, 0, "bb.block.world.grass"],
	[2, 10, 1, 44, "bb.block.tropical.palm-leaf"],
	[3, 10, 1, 15, "bb.block.tropical.palm-leaf"],
	[4, 9, 1, 92, "bb.block.tropical.palm-leaf"],
	[4, 10, 1, 74, "bb.block.tropical.palm-leaf"],
	[5, 8, 1, 15, "bb.block.tropical.palm-leaf"],
	[5, 9, 1, 74, "bb.block.tropical.palm-leaf"],
	[5, 7, 1, 83, "bb.block.tropical.palm-leaf"],
	[1, 9, 2, 21, "bb.block.tropical.palm-leaf"],
	[1, 10, 2, 42, "bb.block.tropical.palm-leaf"],
	[1, 10, 3, 15, "bb.block.tropical.palm-leaf"],
	[1, 10, 4, 26, "bb.block.tropical.palm-leaf"],
	[1, 9, 4, 85, "bb.block.tropical.palm-leaf"],
	[1, 9, 5, 26, "bb.block.tropical.palm-leaf"],
	[1, 7, 5, 90, "bb.block.tropical.palm-leaf"],
	[1, 8, 5, 15, "bb.block.tropical.palm-leaf"],
	[2, 8, 2, 210, "bb.block.tropical.palm-leaf"],
	[3, 8, 2, 0, "bb.block.world.grass"],
	[2, 8, 3, 0, "bb.block.world.grass"],
	[3, 8, 3, 110, "bb.block.tropical.palm-leaf"],
	[2, 9, 2, 168, "bb.block.tropical.palm-leaf"],
	[3, 9, 2, 66, "bb.block.tropical.palm-leaf"],
	[2, 9, 3, 24, "bb.block.tropical.palm-leaf"],
	[3, 9, 3, 161, "bb.block.tropical.palm-leaf"],
	[3, 7, 3, 84, "bb.block.tropical.palm-leaf"],
	[-1, 7, 2, 0, "bb.block.world.grass"],
	[0, 7, 2, 0, "bb.block.world.grass"],
	[-1, 7, 3, 81, "bb.block.tropical.palm-leaf"],
	[0, 7, 3, 0, "bb.block.world.grass"],
	[-1, 8, 2, 0, "bb.block.world.grass"],
	[0, 8, 2, 216, "bb.block.tropical.palm-leaf"],
	[-1, 8, 3, 71, "bb.block.tropical.palm-leaf"],
	[0, 8, 3, 0, "bb.block.world.grass"],
	[-1, 9, 2, 56, "bb.block.tropical.palm-leaf"],
	[0, 9, 2, 162, "bb.block.tropical.palm-leaf"],
	[-1, 9, 3, 164, "bb.block.tropical.palm-leaf"],
	[0, 9, 3, 18, "bb.block.tropical.palm-leaf"],
	[-1, 7, -1, 82, "bb.block.tropical.palm-leaf"],
	[0, 7, -1, 0, "bb.block.world.grass"],
	[-1, 7, 0, 0, "bb.block.world.grass"],
	[0, 7, 0, 0, "bb.block.world.grass"],
	[-1, 8, -1, 23, "bb.block.tropical.palm-leaf"],
	[0, 8, -1, 0, "bb.block.world.grass"],
	[-1, 8, 0, 0, "bb.block.world.grass"],
	[0, 8, 0, 212, "bb.block.tropical.palm-leaf"],
	[-1, 9, -1, 168, "bb.block.tropical.palm-leaf"],
	[0, 9, -1, 97, "bb.block.tropical.palm-leaf"],
	[-1, 9, 0, 50, "bb.block.tropical.palm-leaf"],
	[0, 9, 0, 161, "bb.block.tropical.palm-leaf"],
	[2, 7, -1, 0, "bb.block.world.grass"],
	[3, 7, -1, 88, "bb.block.tropical.palm-leaf"],
	[2, 7, 0, 0, "bb.block.world.grass"],
	[3, 7, 0, 0, "bb.block.world.grass"],
	[2, 8, -1, 0, "bb.block.world.grass"],
	[3, 8, -1, 55, "bb.block.tropical.palm-leaf"],
	[2, 8, 0, 209, "bb.block.tropical.palm-leaf"],
	[3, 8, 0, 0, "bb.block.world.grass"],
	[2, 9, -1, 100, "bb.block.tropical.palm-leaf"],
	[3, 9, -1, 162, "bb.block.tropical.palm-leaf"],
	[2, 9, 0, 164, "bb.block.tropical.palm-leaf"],
	[3, 9, 0, 72, "bb.block.tropical.palm-leaf"],
	[1, 6, 0, 15, "bb.block.tropical.mango"],
	[1, 7, 0, 101, "bb.block.tropical.dark-chevron-wood"],
	[1, 8, 0, 101, "bb.block.tropical.dark-chevron-wood"],
	[2, 7, 1, 15, "bb.block.tropical.mango"],
	[2, 8, 1, 74, "bb.block.tropical.dark-chevron-wood"],
	[0, 7, 1, 15, "bb.block.tropical.mango"],
	[0, 8, 1, 58, "bb.block.tropical.dark-chevron-wood"],
	[1, 6, 2, 15, "bb.block.tropical.mango"],
	[1, 7, 2, 26, "bb.block.tropical.dark-chevron-wood"],
	[1, 8, 2, 26, "bb.block.tropical.dark-chevron-wood"],
];

export const SHORT_PALM_TREE: TerrainObject = [
	[0, 0, 0, 101, "bb.block.tropical.palm-log"],
	[0, 0, 1, 15, "bb.block.tropical.palm-log"],
	[0, 0, 2, 26, "bb.block.tropical.palm-log"],
	[-1, 0, 1, 58, "bb.block.tropical.palm-log"],
	[1, 0, 1, 35, "bb.block.tropical.palm-log"],
	[0, 1, 1, 15, "bb.block.tropical.palm-log"],
	[0, 2, 1, 15, "bb.block.tropical.palm-log"],
	[0, 3, 1, 15, "bb.block.tropical.palm-log"],
	[0, 4, 1, 15, "bb.block.tropical.palm-log"],
	[0, 5, 1, 15, "bb.block.tropical.palm-log"],
	[0, 6, 1, 15, "bb.block.tropical.jungle-roots"],
	[0, 7, 1, 15, "bb.block.tropical.jungle-roots"],
	[0, 8, 1, 0, "bb.block.tropical.palm-leaf"],
	[0, 9, 1, 0, "bb.block.color.red"],
	[-2, 7, -1, 82, "bb.block.tropical.palm-leaf"],
	[-1, 7, -1, 0, "bb.block.color.red"],
	[-2, 7, 0, 0, "bb.block.color.red"],
	[-1, 7, 0, 0, "bb.block.tropical.palm-leaf"],
	[-2, 8, -1, 77, "bb.block.tropical.palm-leaf"],
	[-1, 8, -1, 0, "bb.block.color.red"],
	[-2, 8, 0, 0, "bb.block.color.red"],
	[-1, 8, 0, 0, "bb.block.tropical.palm-leaf"],
	[-2, 9, -1, 168, "bb.block.tropical.palm-leaf"],
	[-1, 9, -1, 97, "bb.block.tropical.palm-leaf"],
	[-2, 9, 0, 50, "bb.block.tropical.palm-leaf"],
	[-1, 9, 0, 161, "bb.block.tropical.palm-leaf"],
	[-4, 6, 1, 92, "bb.block.tropical.palm-leaf"],
	[-3, 6, 1, 0, "bb.block.color.red"],
	[-2, 6, 1, 0, "bb.block.color.red"],
	[-4, 7, 1, 15, "bb.block.tropical.palm-leaf"],
	[-3, 7, 1, 0, "bb.block.color.red"],
	[-2, 7, 1, 0, "bb.block.color.red"],
	[-4, 8, 1, 58, "bb.block.tropical.palm-leaf"],
	[-3, 8, 1, 83, "bb.block.tropical.palm-leaf"],
	[-2, 8, 1, 0, "bb.block.tropical.palm-leaf"],
	[-4, 9, 1, 0, "bb.block.color.red"],
	[-3, 9, 1, 58, "bb.block.tropical.palm-leaf"],
	[-2, 9, 1, 15, "bb.block.tropical.palm-leaf"],
	[-2, 7, 2, 0, "bb.block.color.red"],
	[-1, 7, 2, 0, "bb.block.tropical.palm-leaf"],
	[-2, 7, 3, 81, "bb.block.tropical.palm-leaf"],
	[-1, 7, 3, 0, "bb.block.color.red"],
	[-2, 8, 2, 0, "bb.block.color.red"],
	[-1, 8, 2, 0, "bb.block.tropical.palm-leaf"],
	[-2, 8, 3, 107, "bb.block.tropical.palm-leaf"],
	[-1, 8, 3, 0, "bb.block.color.red"],
	[-2, 9, 2, 56, "bb.block.tropical.palm-leaf"],
	[-1, 9, 2, 162, "bb.block.tropical.palm-leaf"],
	[-2, 9, 3, 164, "bb.block.tropical.palm-leaf"],
	[-1, 9, 3, 18, "bb.block.tropical.palm-leaf"],
	[-1, 7, 1, 15, "bb.block.tropical.black-fabric"],
	[-1, 8, 1, 53, "bb.block.tropical.palm-leaf"],
	[-1, 9, 1, 74, "bb.block.tropical.palm-leaf"],
	[0, 7, 2, 15, "bb.block.tropical.black-fabric"],
	[0, 7, 3, 0, "bb.block.color.red"],
	[0, 7, 4, 0, "bb.block.color.red"],
	[0, 8, 2, 21, "bb.block.tropical.palm-leaf"],
	[0, 8, 3, 0, "bb.block.tropical.palm-leaf"],
	[0, 8, 4, 106, "bb.block.tropical.palm-leaf"],
	[0, 9, 2, 101, "bb.block.tropical.palm-leaf"],
	[0, 9, 3, 15, "bb.block.tropical.palm-leaf"],
	[0, 9, 4, 37, "bb.block.tropical.palm-leaf"],
	[0, 6, 5, 90, "bb.block.tropical.palm-leaf"],
	[0, 7, 5, 15, "bb.block.tropical.palm-leaf"],
	[0, 8, 5, 37, "bb.block.tropical.palm-leaf"],
	[4, 6, 1, 83, "bb.block.tropical.palm-leaf"],
	[4, 7, 1, 15, "bb.block.tropical.palm-leaf"],
	[4, 8, 1, 74, "bb.block.tropical.palm-leaf"],
	[3, 8, 1, 53, "bb.block.tropical.palm-leaf"],
	[3, 9, 1, 74, "bb.block.tropical.palm-leaf"],
	[1, 7, 1, 15, "bb.block.tropical.black-fabric"],
	[2, 7, 1, 0, "bb.block.color.red"],
	[1, 8, 1, 69, "bb.block.tropical.palm-leaf"],
	[2, 8, 1, 0, "bb.block.tropical.palm-leaf"],
	[1, 9, 1, 58, "bb.block.tropical.palm-leaf"],
	[2, 9, 1, 15, "bb.block.tropical.palm-leaf"],
	[1, 7, 2, 0, "bb.block.tropical.palm-leaf"],
	[2, 7, 2, 0, "bb.block.color.red"],
	[1, 7, 3, 0, "bb.block.color.red"],
	[2, 7, 3, 84, "bb.block.tropical.palm-leaf"],
	[1, 8, 2, 0, "bb.block.tropical.palm-leaf"],
	[2, 8, 2, 0, "bb.block.color.red"],
	[1, 8, 3, 0, "bb.block.color.red"],
	[2, 8, 3, 61, "bb.block.tropical.palm-leaf"],
	[1, 9, 2, 168, "bb.block.tropical.palm-leaf"],
	[2, 9, 2, 66, "bb.block.tropical.palm-leaf"],
	[1, 9, 3, 24, "bb.block.tropical.palm-leaf"],
	[2, 9, 3, 161, "bb.block.tropical.palm-leaf"],
	[1, 7, -1, 0, "bb.block.color.red"],
	[2, 7, -1, 88, "bb.block.tropical.palm-leaf"],
	[1, 7, 0, 0, "bb.block.tropical.palm-leaf"],
	[2, 7, 0, 0, "bb.block.color.red"],
	[1, 8, -1, 0, "bb.block.color.red"],
	[2, 8, -1, 29, "bb.block.tropical.palm-leaf"],
	[1, 8, 0, 0, "bb.block.tropical.palm-leaf"],
	[2, 8, 0, 0, "bb.block.color.red"],
	[1, 9, -1, 100, "bb.block.tropical.palm-leaf"],
	[2, 9, -1, 162, "bb.block.tropical.palm-leaf"],
	[1, 9, 0, 164, "bb.block.tropical.palm-leaf"],
	[2, 9, 0, 72, "bb.block.tropical.palm-leaf"],
	[0, 7, -3, 15, "bb.block.tropical.palm-leaf"],
	[0, 7, -2, 0, "bb.block.color.red"],
	[0, 7, -1, 0, "bb.block.color.red"],
	[0, 7, 0, 15, "bb.block.tropical.black-fabric"],
	[0, 8, -3, 101, "bb.block.tropical.palm-leaf"],
	[0, 8, -2, 21, "bb.block.tropical.palm-leaf"],
	[0, 8, -1, 0, "bb.block.tropical.palm-leaf"],
	[0, 8, 0, 106, "bb.block.tropical.palm-leaf"],
	[0, 9, -3, 0, "bb.block.color.red"],
	[0, 9, -2, 101, "bb.block.tropical.palm-leaf"],
	[0, 9, -1, 15, "bb.block.tropical.palm-leaf"],
	[0, 9, 0, 26, "bb.block.tropical.palm-leaf"],
	[0, 6, -3, 85, "bb.block.tropical.palm-leaf"],
];

export const FERN_PLANT: TerrainObject = [
	[0, 1, -1, 225, "bb.block.tropical.canopy-tree-leaves"],
	[0, 1, 0, 37, "bb.block.tropical.canopy-tree-leaves"],
	[0, 1, 1, 38, "bb.block.tropical.canopy-tree-leaves"],
	[0, 0, 0, 106, "bb.block.tropical.canopy-tree-leaves"],
	[0, 0, 1, 15, "bb.block.tropical.canopy-tree-log"],
	[-1, 0, 1, 53, "bb.block.tropical.canopy-tree-leaves"],
	[-1, 1, 1, 35, "bb.block.tropical.canopy-tree-leaves"],
	[-2, 1, 1, 178, "bb.block.tropical.canopy-tree-leaves"],
	[1, 1, 1, 44, "bb.block.tropical.canopy-tree-leaves"],
	[2, 1, 1, 194, "bb.block.tropical.canopy-tree-leaves"],
	[1, 0, 1, 69, "bb.block.tropical.canopy-tree-leaves"],
	[0, 0, 2, 149, "bb.block.tropical.canopy-tree-leaves"],
	[0, 1, 2, 170, "bb.block.tropical.canopy-tree-leaves"],
	[0, 1, 3, 152, "bb.block.tropical.canopy-tree-leaves"],
];

export const WILD_WEST_DESERT_CACTUS1: TerrainObject = [
	[0, 0, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 1, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 2, 0, 15, "bb.block.wildwest.cactus-v"],
];

export const WILD_WEST_DESERT_CACTUS2: TerrainObject = [
	[0, 0, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 1, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 2, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 3, 0, 15, "bb.block.wildwest.cactus-v"],
	[1, 1, 0, 69, "bb.block.wildwest.cactus-v"],
];

export const WILD_WEST_DESERT_CACTUS3: TerrainObject = [
	[0, 0, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 1, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 2, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 3, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 4, 0, 15, "bb.block.wildwest.cactus-v"],
	[0, 1, 1, 21, "bb.block.wildwest.cactus-v"],
	[0, 2, -1, 106, "bb.block.wildwest.cactus-v"],
];

export const WILD_WEST_DESERT_ROCK1: TerrainObject = [
	[0, 0, 0, 15, "bb.block.wildwest.sandstone"],
	[1, 0, 0, 15, "bb.block.wildwest.sandstone"],
	[0, 0, 1, 15, "bb.block.wildwest.sandstone"],
	[1, 0, 1, 15, "bb.block.wildwest.sandstone"],
	[0, 1, 0, 46, "bb.block.wildwest.sandstone"],
	[1, 1, 0, 43, "bb.block.wildwest.sandstone"],
	[0, 1, 1, 45, "bb.block.wildwest.sandstone"],
	[1, 1, 1, 39, "bb.block.wildwest.sandstone"],
];

export const WILD_WEST_DESERT_ROCK2: TerrainObject = [
	[0, 0, 0, 15, "bb.block.wildwest.sandstone"],
	[1, 0, 0, 15, "bb.block.wildwest.sandstone"],
	[2, 0, 0, 15, "bb.block.wildwest.sandstone"],
	[0, 0, 1, 15, "bb.block.wildwest.sandstone"],
	[1, 0, 1, 15, "bb.block.wildwest.sandstone"],
	[2, 0, 1, 15, "bb.block.wildwest.sandstone"],
	[0, 0, 2, 15, "bb.block.wildwest.sandstone"],
	[1, 0, 2, 15, "bb.block.wildwest.sandstone"],
	[2, 0, 2, 15, "bb.block.wildwest.sandstone"],
	[0, 1, 0, 46, "bb.block.wildwest.sandstone"],
	[1, 1, 0, 15, "bb.block.wildwest.sandstone"],
	[2, 1, 0, 43, "bb.block.wildwest.sandstone"],
	[0, 1, 1, 15, "bb.block.wildwest.sandstone"],
	[1, 1, 1, 15, "bb.block.wildwest.sandstone"],
	[2, 1, 1, 15, "bb.block.wildwest.sandstone"],
	[0, 1, 2, 45, "bb.block.wildwest.sandstone"],
	[1, 1, 2, 15, "bb.block.wildwest.sandstone"],
	[2, 1, 2, 39, "bb.block.wildwest.sandstone"],
	[0, 2, 0, 40, "bb.block.wildwest.sandstone"],
	[1, 2, 0, 42, "bb.block.wildwest.sandstone"],
	[2, 2, 0, 34, "bb.block.wildwest.sandstone"],
	[0, 2, 1, 44, "bb.block.wildwest.sandstone"],
	[1, 2, 1, 15, "bb.block.wildwest.sandstone"],
	[2, 2, 1, 35, "bb.block.wildwest.sandstone"],
	[0, 2, 2, 36, "bb.block.wildwest.sandstone"],
	[1, 2, 2, 37, "bb.block.wildwest.sandstone"],
	[2, 2, 2, 33, "bb.block.wildwest.sandstone"],
];

export const SNOWY_TUNDRA_PINE_TREE: TerrainObject = [
	[0, 0, 0, 58, "bb.block.tropical.pine-log"],
	[1, 0, 0, 15, "bb.block.tropical.pine-log"],
	[2, 0, 0, 74, "bb.block.tropical.pine-log"],
	[1, 0, -1, 101, "bb.block.tropical.pine-log"],
	[1, 0, 1, 26, "bb.block.tropical.pine-log"],
	[1, 1, 0, 15, "bb.block.tropical.pine-log"],
	[1, 2, 0, 15, "bb.block.tropical.pine-log"],
	[1, 3, 0, 15, "bb.block.tropical.pine-log"],
	[1, 4, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 5, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 6, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 7, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 8, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 9, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 10, 0, 15, "bb.block.tropical.pine-needles"],
	[1, 11, 0, 39, "bb.block.world.snow"],
	[1, 12, 0, 33, "bb.block.world.snow"],
	[0, 3, 0, 58, "bb.block.tropical.pine-needles"],
	[0, 4, 0, 15, "bb.block.tropical.pine-needles"],
	[0, 5, 0, 15, "bb.block.tropical.pine-needles"],
	[0, 6, 0, 15, "bb.block.tropical.pine-needles"],
	[0, 7, 0, 15, "bb.block.tropical.pine-needles"],
	[0, 8, 0, 59, "bb.block.tropical.snowy-pine-needles"],
	[0, 9, 0, 58, "bb.block.tropical.snowy-pine-needles"],
	[0, 10, 0, 44, "bb.block.world.snow"],
	[1, 3, 1, 26, "bb.block.tropical.pine-needles"],
	[1, 4, 1, 15, "bb.block.tropical.pine-needles"],
	[1, 5, 1, 15, "bb.block.tropical.pine-needles"],
	[1, 6, 1, 15, "bb.block.tropical.pine-needles"],
	[1, 7, 1, 15, "bb.block.tropical.pine-needles"],
	[1, 8, 1, 30, "bb.block.tropical.snowy-pine-needles"],
	[1, 9, 1, 26, "bb.block.world.snow"],
	[1, 10, 1, 26, "bb.block.world.snow"],
	[2, 3, 0, 74, "bb.block.tropical.pine-needles"],
	[2, 4, 0, 15, "bb.block.tropical.pine-needles"],
	[2, 5, 0, 15, "bb.block.tropical.pine-needles"],
	[2, 6, 0, 15, "bb.block.tropical.pine-needles"],
	[2, 7, 0, 15, "bb.block.tropical.pine-needles"],
	[2, 8, 0, 75, "bb.block.tropical.snowy-pine-needles"],
	[2, 9, 0, 74, "bb.block.world.snow"],
	[2, 10, 0, 74, "bb.block.world.snow"],
	[1, 3, -1, 101, "bb.block.tropical.pine-needles"],
	[1, 4, -1, 15, "bb.block.tropical.pine-needles"],
	[1, 5, -1, 15, "bb.block.tropical.pine-needles"],
	[1, 6, -1, 15, "bb.block.tropical.pine-needles"],
	[1, 7, -1, 15, "bb.block.tropical.pine-needles"],
	[1, 8, -1, 103, "bb.block.tropical.snowy-pine-needles"],
	[1, 9, -1, 101, "bb.block.tropical.snowy-pine-needles"],
	[1, 10, -1, 101, "bb.block.world.snow"],
	[-1, 3, -2, 100, "bb.block.tropical.pine-needles"],
	[0, 3, -2, 97, "bb.block.tropical.pine-needles"],
	[1, 3, -2, 90, "bb.block.tropical.pine-needles"],
	[2, 3, -2, 100, "bb.block.tropical.pine-needles"],
	[3, 3, -2, 66, "bb.block.tropical.pine-needles"],
	[-1, 3, -1, 50, "bb.block.tropical.pine-needles"],
	[0, 3, -1, 210, "bb.block.tropical.pine-needles"],
	[2, 3, -1, 216, "bb.block.tropical.pine-needles"],
	[3, 3, -1, 72, "bb.block.tropical.pine-needles"],
	[-1, 3, 0, 83, "bb.block.tropical.pine-needles"],
	[3, 3, 0, 92, "bb.block.tropical.pine-needles"],
	[-1, 3, 1, 56, "bb.block.tropical.pine-needles"],
	[0, 3, 1, 209, "bb.block.tropical.pine-needles"],
	[2, 3, 1, 212, "bb.block.tropical.pine-needles"],
	[3, 3, 1, 66, "bb.block.tropical.pine-needles"],
	[-1, 3, 2, 50, "bb.block.tropical.pine-needles"],
	[0, 3, 2, 18, "bb.block.tropical.pine-needles"],
	[1, 3, 2, 85, "bb.block.tropical.pine-needles"],
	[2, 3, 2, 24, "bb.block.tropical.pine-needles"],
	[3, 3, 2, 18, "bb.block.tropical.pine-needles"],
	[1, 3, -3, 101, "bb.block.tropical.pine-needles"],
	[1, 3, 3, 26, "bb.block.tropical.pine-needles"],
	[-2, 3, 0, 58, "bb.block.tropical.pine-needles"],
	[4, 3, 0, 74, "bb.block.tropical.pine-needles"],
	[-1, 4, -2, 0, "bb.block.world.grass"],
	[0, 4, -2, 109, "bb.block.tropical.pine-needles"],
	[1, 4, -2, 15, "bb.block.tropical.pine-needles"],
	[2, 4, -2, 103, "bb.block.tropical.pine-needles"],
	[3, 4, -2, 0, "bb.block.world.grass"],
	[-1, 4, -1, 109, "bb.block.tropical.pine-needles"],
	[0, 4, -1, 15, "bb.block.tropical.pine-needles"],
	[2, 4, -1, 15, "bb.block.tropical.pine-needles"],
	[3, 4, -1, 103, "bb.block.tropical.pine-needles"],
	[-1, 4, 0, 15, "bb.block.tropical.pine-needles"],
	[3, 4, 0, 15, "bb.block.tropical.pine-needles"],
	[-1, 4, 1, 59, "bb.block.tropical.pine-needles"],
	[0, 4, 1, 15, "bb.block.tropical.pine-needles"],
	[2, 4, 1, 15, "bb.block.tropical.pine-needles"],
	[3, 4, 1, 27, "bb.block.tropical.pine-needles"],
	[-1, 4, 2, 0, "bb.block.world.grass"],
	[0, 4, 2, 59, "bb.block.tropical.pine-needles"],
	[1, 4, 2, 15, "bb.block.tropical.pine-needles"],
	[2, 4, 2, 27, "bb.block.tropical.pine-needles"],
	[3, 4, 2, 0, "bb.block.world.grass"],
	[-1, 5, 0, 58, "bb.block.tropical.pine-needles"],
	[3, 5, 0, 35, "bb.block.tropical.pine-needles"],
	[1, 5, -2, 42, "bb.block.tropical.pine-needles"],
	[1, 5, 2, 37, "bb.block.tropical.pine-needles"],
	[1, 6, -2, 101, "bb.block.tropical.snowy-pine-needles"],
	[1, 6, 2, 26, "bb.block.tropical.snowy-pine-needles"],
	[1, 7, -2, 101, "bb.block.tropical.pine-needles"],
	[1, 7, 2, 26, "bb.block.tropical.pine-needles"],
	[-1, 6, 0, 58, "bb.block.tropical.snowy-pine-needles"],
	[3, 6, 0, 74, "bb.block.tropical.snowy-pine-needles"],
	[-1, 7, 0, 58, "bb.block.tropical.pine-needles"],
	[3, 7, 0, 74, "bb.block.tropical.pine-needles"],
	[0, 5, -1, 46, "bb.block.tropical.pine-needles"],
	[2, 5, -1, 43, "bb.block.tropical.pine-needles"],
	[0, 5, 1, 45, "bb.block.tropical.pine-needles"],
	[2, 5, 1, 39, "bb.block.tropical.pine-needles"],
	[0, 6, -1, 40, "bb.block.tropical.pine-needles"],
	[2, 6, -1, 34, "bb.block.tropical.pine-needles"],
	[0, 6, 1, 36, "bb.block.tropical.pine-needles"],
	[2, 6, 1, 33, "bb.block.tropical.pine-needles"],
	[0, 7, -1, 62, "bb.block.tropical.snowy-pine-needles"],
	[2, 7, -1, 103, "bb.block.tropical.snowy-pine-needles"],
	[0, 7, 1, 59, "bb.block.tropical.snowy-pine-needles"],
	[2, 7, 1, 78, "bb.block.tropical.snowy-pine-needles"],
	[0, 8, -1, 168, "bb.block.tropical.snowy-pine-needles"],
	[2, 8, -1, 162, "bb.block.tropical.snowy-pine-needles"],
	[0, 8, 1, 164, "bb.block.tropical.snowy-pine-needles"],
	[2, 8, 1, 161, "bb.block.tropical.snowy-pine-needles"],
];

export const SNOWY_TUNDRA_ICE: TerrainObject = [
	[0, 0, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -1, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -2, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -3, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -4, 0, BLOCK_CUBE, "bb.block.world.ice"],
];

export const SNOWY_TUNDRA_CRACK_IN_ICE: TerrainObject = [
	[0, 0, 0, BLOCK_AIR, "bb.block.world.ice"],
	[0, -1, 0, BLOCK_AIR, "bb.block.world.ice"],
	[0, -2, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -3, 0, BLOCK_CUBE, "bb.block.world.ice"],
	[0, -4, 0, BLOCK_CUBE, "bb.block.world.ice"],
];

export const SNOWY_TUNDRA_ICE_SPIKE1: TerrainObject = [
	[0, 1, 0, 40, "bb.block.world.ice"],
	[1, 1, 0, 34, "bb.block.world.ice"],
	[0, 1, 1, 36, "bb.block.world.ice"],
	[1, 1, 1, 33, "bb.block.world.ice"],
];

export const SNOWY_TUNDRA_ICE_SPIKE2: TerrainObject = [
	[0, 1, 0, 40, "bb.block.world.ice"],
	[1, 1, 0, 34, "bb.block.world.ice"],
	[0, 1, 1, 44, "bb.block.world.ice"],
	[1, 1, 1, 35, "bb.block.world.ice"],
	[0, 1, 2, 50, "bb.block.world.ice"],
	[1, 1, 2, 33, "bb.block.world.ice"],
];

export const SNOWY_TUNDRA_ICE_SPIKE3: TerrainObject = [
	[0, 1, 0, 42, "bb.block.world.ice"],
	[1, 1, 0, 34, "bb.block.world.ice"],
	[0, 1, 1, 59, "bb.block.world.ice"],
	[1, 1, 1, 35, "bb.block.world.ice"],
	[0, 1, 2, 36, "bb.block.world.ice"],
	[1, 1, 2, 33, "bb.block.world.ice"],
	[-1, 1, 0, 40, "bb.block.world.ice"],
	[-1, 1, 1, 36, "bb.block.world.ice"],
	[0, 2, 1, 36, "bb.block.world.ice"],
];
